<script setup lang="ts">
import { MessageDTO } from "@telia/b2b-rest-client/src/corp-basket-management";
import { applyPureReactInVue } from "veaury";
import { Notification, Paragraph } from "@purpurds/purpur";

const PurpurNotification = applyPureReactInVue(Notification);
const PurpurParagraph = applyPureReactInVue(Paragraph);

interface Props {
  messages: MessageDTO[];
}
defineProps<Props>();
</script>

<template>
  <div
    t-id="configuration-message-root"
    v-if="messages.length !== 0"
    class="configuration-messages"
  >
    <ul class="configuration-messages__list">
      <li v-for="message of messages" class="configuration-messages__list-item" :key="message.id">
        <PurpurNotification :heading="message.header">
          <PurpurParagraph>{{ message.description }}</PurpurParagraph>
        </PurpurNotification>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/tokens";

.configuration-messages {
  $root: &;
  margin-bottom: $telia-spacing-32;

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__list-item + &__list-item {
    margin-top: $telia-spacing-16;
  }
}
</style>

import { ConfigurationDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { InstallationSettingRequestDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { ConfigurationItemDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { InstallationSettingDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { PersonSettingDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";

export function installationSettingsFromConfiguration(
  configuration: ConfigurationDTO | null,
  oldInstallationSettings?: InstallationSettingRequestDTO[]
): InstallationSettingRequestDTO[] {
  return (
    configuration?.configurationItems?.[0]?.installationSettings?.map((c) => {
      const oldInstallationSetting = oldInstallationSettings?.find(
        (os) => os.settingId === c.id && os.createContactInformation
      );
      return (
        oldInstallationSetting ?? {
          settingId: c.id!,
          type: c.type!,
          value: getAddressOrPersonValue(c),
        }
      );
    }) || []
  );
}

export function getAddressOrPersonValue(setting) {
  if (setting.type === "ADDRESS") {
    if (!isAddressSettingComplete(setting)) {
      return null;
    }
    return setting.address.pointId;
  } else {
    return setting.relatedParty;
  }
}

export function getPartiesFromConfiguration(configuration: ConfigurationDTO): PersonSettingDTO[] {
  return (
    configuration?.configurationItems
      ?.map(
        (configurationItem: ConfigurationItemDTO) => configurationItem.installationSettings || []
      )
      .flat()
      .filter((installationSetting: InstallationSettingDTO) => isPersonSetting(installationSetting))
      .filter((personSetting: PersonSettingDTO) => personSetting.relatedParty) ?? []
  );
}

const isPersonSetting = (setting: InstallationSettingDTO) => setting.type === "PERSON";

function isAddressSettingComplete({ address }) {
  return !!(address?.pointId && address?.fullAddress);
}

<template>
  <div class="order-contact-setting">
    <div class="order-contact-setting__heading">
      <telia-heading tag="h3" variant="title-300">{{ heading }}</telia-heading>
    </div>
    <telia-p v-if="description" class="order-contact-setting__description">{{
      description
    }}</telia-p>
    <div>
      <div
        v-if="personToDisplay && !showCreatePersonForm"
        class="order-contact-setting__data-border"
      >
        <display-person :person="personToDisplay" @change-person="showCreatePersonForm = true" />
      </div>
      <div v-else-if="isMissingPartyInformation">
        <div
          class="order-contact-setting__data-border"
          t-id="order-contact-setting_missing-party-info-message"
        >
          <telia-p>
            {{ t("createPerson.unableToFetchContactData") }}
          </telia-p>
        </div>
      </div>
      <div v-else>
        <create-person
          :disable-revert="!personToDisplay"
          @revert="showCreatePersonForm = false"
          @submit-create-person="createPerson"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../../locale";
import CreatePerson from "./create-person";
import DisplayPerson from "./display-person";
import { toDisplayPerson } from "../../../utils/user-utils";

export default defineComponent({
  name: "order-contact-setting",
  mixins: [translateMixin],
  components: { CreatePerson, DisplayPerson },
  props: {
    person: {
      type: Object,
      required: true,
    },
    partyInformationMap: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      errorCode: null,
      showCreatePersonForm: false,
      newPerson: null,
    };
  },
  created() {
    translateSetup(["mybusinessotpconfigure"]);
  },
  computed: {
    description() {
      return this.t(`mybusinessotpconfigure.${this.person.id}Description`);
    },
    heading() {
      return this.t(`mybusinessotpconfigure.${this.person.id}Heading`);
    },
    isMissingPartyInformation() {
      return !this.partyInformation && this.person.relatedParty;
    },
    partyInformation() {
      return this.partyInformationMap[this.person?.relatedParty];
    },
    personToDisplay() {
      return (
        this.newPerson ||
        (this.partyInformation && toDisplayPerson(this.partyInformation, this.person.id)) ||
        null
      );
    },
  },
  methods: {
    createPerson(formData) {
      this.$emit("update-installation-setting", formData);
      this.newPerson = formData;
      this.showCreatePersonForm = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/borders/tokens";

.order-contact-setting {
  background: $telia-white;
  border-radius: 1.6rem;
  padding: $telia-spacing-32;

  &__heading {
    padding-bottom: $telia-spacing-16;
  }
  &__description {
    padding-bottom: $telia-spacing-20;
  }
  &__data-border {
    padding: $telia-spacing-24;
    border: 1px solid $telia-gray-500;
    border-radius: $telia-border-radius-8;
  }
}
</style>

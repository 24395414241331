<template>
  <div class="b2b-order-flow-configuration" :class="openPages ? 'openpages' : 'mybusiness'">
    <telia-grid v-if="showNotice">
      <telia-notification
        heading-tag="h3"
        status="information"
        t-id="b2b-order-flow-configuration__basket-contents-notice"
      >
        <span slot="heading">{{ t("itemsInBasketNotice.heading") }}</span>
        <span slot="content">
          <telia-p>{{ t("itemsInBasketNotice.message") }}</telia-p>
        </span>
      </telia-notification>
    </telia-grid>
    <b2b-basket-wrapper
      v-if="tscid"
      t-id="b2b-order-flow-configuration__basket-wrapper"
      :isOpen="isOpen"
      :tscid="tscid"
      @update-drawer-state="handleDrawerState"
    />
    <broadband-configuration
      :tscid="tscid"
      :scope-id="scopeId"
      :configuration-id="configurationId"
      :open-pages="openPages"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getParamsFromUrl } from "./utils/urlUtils";
import { corpBasketManagement } from "@telia/b2b-rest-client";
import { translateSetup, translateMixin } from "./locale";
import { setCurrentLanguage } from "@telia/b2b-i18n";
import BroadbandConfiguration from "./components/broadband-configuration/broadband-configuration.vue";
import { FeatureFlags } from "./feature-flags/featureFlags";
import { useFlag } from "@unleash/proxy-client-vue";

export default defineComponent({
  name: "B2BOrderFlowConfiguration",
  mixins: [translateMixin],
  components: {
    BroadbandConfiguration,
  },
  props: {
    openPages: { type: [Boolean, undefined], default: false },
  },
  setup() {
    const multiCheckoutFeatureEnabled = useFlag(FeatureFlags.MULTICHECKOUT);
    return {
      multiCheckoutFeatureEnabled,
    };
  },
  data() {
    return {
      tscid: null,
      scopeId: null,
      configurationId: null,
      hasItemsInBasket: false,
      isOpen: false,
    };
  },
  async created() {
    if (this.openPages) {
      setCurrentLanguage("sv");
    }
    translateSetup();

    const urlParams = getParamsFromUrl(this.openPages);
    this.scopeId = urlParams.scopeId;
    this.tscid = urlParams.tscid;
    this.configurationId = urlParams.configurationId;

    if (!this.openPages) {
      corpBasketManagement.BasketControllerService.getBasket(this.scopeId, this.tscid).then(
        async (res) => {
          this.hasItemsInBasket = res.broadbandItems.length > 0;
        }
      );
    }
  },
  methods: {
    handleDrawerState(event) {
      const [isOpen] = event.detail;
      this.isOpen = isOpen;
    },
  },
  computed: {
    showNotice() {
      return !this.multiCheckoutFeatureEnabled && this.hasItemsInBasket;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.b2b-order-flow-configuration {
  background-color: $telia-gray-50;

  &.mybusiness {
    min-height: calc(100vh - 484px);
  }

  &.openpages {
    min-height: calc(100vh - 608px);
  }
}

.telia-grid {
  padding: $telia-spacing-16 0;
}
</style>

<template>
  <div class="configuration-summary-item" t-id="configuration-summary-item">
    <div class="configuration-summary-item__list">
      <telia-row v-for="productDetail in productDetails" :key="productDetail.label">
        <telia-col width="12">
          <div
            class="configuration-summary-item__detail-wrapper"
            t-id="configuration-summary-item__detail-wrapper"
          >
            <DetailIcon :src="productDetail.icon" />
            <telia-p class="configuration-summary-item__detail-label">
              {{ productDetail.label }}
            </telia-p>
          </div>
        </telia-col>
      </telia-row>
      <telia-row v-for="childProduct in childProducts" :key="childProduct.label">
        <telia-col width="12">
          <div
            class="configuration-summary-item__detail-wrapper"
            t-id="configuration-summary-item__detail-wrapper"
          >
            <DetailIcon :src="childProduct.icon" />
            <telia-p class="configuration-summary-item__detail-label">
              {{ childProduct.label }}
            </telia-p>
          </div>
        </telia-col>
      </telia-row>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DetailIcon from "./detail-icon.vue";
import type {
  BroadbandItemDTO,
  ProductDetailDTO,
  ChildProductDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";

interface Props {
  broadbandItem?: BroadbandItemDTO;
}

const props = defineProps<Props>();

const productDetails = computed<ProductDetailDTO[]>(() => {
  return props.broadbandItem?.productDetails ?? [];
});

const childProducts = computed<ChildProductDTO[]>(() => {
  return props.broadbandItem?.childProducts ?? [];
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/typography/variables";
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/breakpoints/mixins";

.configuration-summary-item {
  &__detail-wrapper {
    display: flex;
    align-items: center;
    padding: $telia-spacing-8 0;
  }

  &__detail-label {
    margin-left: $telia-spacing-8;
  }

  &__list {
    width: 100%;
    list-style-type: none;
    margin: $telia-spacing-24 0 $telia-spacing-32;
  }

  &__list-item {
    border-bottom: 1px solid $telia-gray-200;
  }
}
</style>

<template>
  <div class="broadband-configuration-step">
    <b2x-toast
      t-id="broadband-configuration-step__update-error"
      class="broadband-configuration-step__update-error"
      :show-toast="hasUpdateError"
      status="warning"
      :header-text="t('updateConfigurationError.heading')"
      :content-text="t('updateConfigurationError.message')"
      @closeToastEvent="handleCloseToast"
    />
    <telia-grid>
      <telia-row>
        <telia-col width="12" class="broadband-configuration-step__config-column">
          <login-link-box
            t-id="b2b-order-flow-configuration__login-link-box"
            v-if="showLoginLinkBox"
            :target-url-value="targetUrl"
            :selected-organisation="selectedOrganisation"
          />
        </telia-col>
        <telia-col width="12" width-md="7" class="broadband-configuration-step__config-column">
          <configuration-card-skeleton
            t-id="broadband-configuration-step__skeleton-loader"
            v-if="getConfigurationPending"
          />
          <configuration-messages :messages="configurationMessages" />
          <div v-if="configurationItems && !getConfigurationPending">
            <configuration-item
              v-for="configurationItem in configurationItems"
              :t-id="'broadband-configuration-step__config-column__item_' + configurationItem.id"
              :configuration-update-is-pending="configurationUpdateIsPending"
              :configuration-item="configurationItem"
              :key="configurationItem.id"
              @change="handleConfigurationItemChange"
              @add-offering="addOffering"
              @remove-offering="removeOffering"
              @accepted-offering="handleAcceptedOffering"
            />
          </div>
          <div v-if="getConfigurationError">
            <telia-notification
              heading-tag="h3"
              status="warning"
              t-id="broadband-configuration-step__no-data-error"
            >
              <span slot="heading">{{ t("broadbandConfigurationError.heading") }}</span>
              <span slot="content">
                <telia-p>{{ t("broadbandConfigurationError.message") }}</telia-p>
              </span>
            </telia-notification>
          </div>
        </telia-col>
        <telia-col width="12" width-md="5">
          <sticky-container>
            <configuration-summary
              :configuration="configuration"
              :configuration-loading="getConfigurationPending"
              :configuration-update-loading="configurationUpdateIsPending"
            >
              <telia-button
                :disabled="nextStepIsDisabled"
                :right-icon="JSON.stringify({ name: 'arrow-right', size: 'sm' })"
                :text="t('nextStep')"
                t-id="b2b-order-flow-configuration__next-button"
                variant="primary"
                size="lg"
                @click="$emit('next-step')"
              >
              </telia-button>
            </configuration-summary>
          </sticky-container>
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ConfigurationItem from "../configuration-item.vue";
import ConfigurationSummary from "../configuration-summary/configuration-summary.vue";
import StickyContainer from "../sticky-container.vue";
import LoginLinkBox from "../login-link-box.vue";
import { translateSetup, translateMixin } from "../../locale";
import ConfigurationCardSkeleton from "../configuration-card-skeleton.vue";
import ConfigurationMessages from "./configuration-messages.vue";
import "@telia/b2x-toast";

export default defineComponent({
  name: "broadband-configuration-step",
  mixins: [translateMixin],
  components: {
    ConfigurationMessages,
    LoginLinkBox,
    ConfigurationItem,
    ConfigurationSummary,
    ConfigurationCardSkeleton,
    StickyContainer,
  },
  props: {
    configuration: { type: Object, default: () => ({}) },
    configurationUpdateIsPending: { type: Boolean, required: true },
    getConfigurationPending: { type: Boolean, required: true },
    getConfigurationError: { type: Boolean, required: true },
    hasUpdateError: { type: Boolean, default: false },
    showLoginLinkBox: { type: Boolean, default: false },
    targetUrl: { type: String },
    selectedOrganisation: { type: String },
    nextStepIsDisabled: { type: Boolean, default: false },
  },
  created() {
    translateSetup();
  },
  computed: {
    configurationItems() {
      return this.configuration?.configurationItems;
    },
    configurationMessages() {
      return this.configuration?.messages ?? [];
    },
  },
  methods: {
    handleConfigurationItemChange(changeObject) {
      this.$emit("selected-option", changeObject);
    },
    handleCloseToast() {
      this.$emit("update-error", false);
    },
    addOffering(event) {
      this.$emit("add-offering", event);
    },
    removeOffering(event) {
      this.$emit("remove-offering", event);
    },
    handleAcceptedOffering(event) {
      this.$emit("accepted-offering", event);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/breakpoints/mixins";

.broadband-configuration-step {
  margin-top: $telia-spacing-32;
  position: relative;

  &__config-column {
    @media (max-width: $telia-breakpoint-medium) {
      margin-bottom: $telia-spacing-32;
    }
  }

  &__update-error {
    position: absolute;
    right: $telia-spacing-32;

    @media screen and (max-width: $telia-breakpoint-medium) {
      margin-left: $telia-spacing-8;
      right: $telia-spacing-8;
    }
  }
}
</style>

import { corpBasketManagement } from "@telia/b2b-rest-client";
import { LanguageEnum } from "@telia/b2b-i18n/dist/commonjs/types";
import {
  AddOfferingRequestDTO as AddOfferingOpenRequestDTO,
  ConfigurationDTO as ConfigurationOpenDTO,
  UpdateConfigurationRequestDTO as UpdateConfigurationOpenRequestDTO,
} from "@telia/b2b-rest-client/dist/corp-open-basket-management";
import {
  AddOfferingRequestDTO,
  ConfigurationDTO,
  UpdateConfigurationRequestDTO,
  FinishConfigurationRequestDTO,
  FinishConfigurationResponseDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";
import { OpenConfigurationControllerService } from "@telia/b2b-rest-client/src/corp-basket-management";

export function finishConfiguration(
  scopeId: string,
  tscid: string,
  configurationId: string,
  requestBody: FinishConfigurationRequestDTO
): Promise<FinishConfigurationResponseDTO> {
  return corpBasketManagement.ConfigurationControllerService.finishConfiguration(
    scopeId,
    tscid,
    configurationId,
    requestBody
  );
}

export function getConfigurationById(
  scopeId: string | undefined,
  tscid: string | undefined,
  configurationId: string,
  language: "SV" | "EN",
  openPages: boolean
): Promise<ConfigurationDTO | ConfigurationOpenDTO> {
  if (openPages) {
    return corpBasketManagement.OpenConfigurationControllerService.getConfigurationById1(
      configurationId,
      "SV"
    );
  } else {
    return corpBasketManagement.ConfigurationControllerService.getConfigurationById(
      scopeId!,
      tscid!,
      configurationId,
      language
    );
  }
}

export function updateConfiguration(
  scopeId: string | undefined,
  tscid: string | undefined,
  configurationId: string,
  language: "SV" | "EN",
  requestBody: UpdateConfigurationRequestDTO | UpdateConfigurationOpenRequestDTO,
  openPages: boolean
): Promise<ConfigurationDTO | ConfigurationOpenDTO> {
  if (openPages) {
    return corpBasketManagement.OpenConfigurationControllerService.updateConfiguration1(
      configurationId,
      requestBody as UpdateConfigurationOpenRequestDTO,
      "SV"
    );
  } else {
    return corpBasketManagement.ConfigurationControllerService.updateConfiguration(
      scopeId!,
      tscid!,
      configurationId,
      requestBody as UpdateConfigurationRequestDTO,
      language
    );
  }
}

export function addOffering(
  scopeId: string | undefined,
  tscid: string | undefined,
  configurationId: string,
  requestBody: AddOfferingRequestDTO | AddOfferingOpenRequestDTO,
  language: "SV" | "EN",
  openPages: boolean
): Promise<ConfigurationDTO | ConfigurationOpenDTO> {
  if (openPages) {
    return corpBasketManagement.OpenConfigurationControllerService.addOffering1(
      configurationId,
      requestBody as AddOfferingOpenRequestDTO,
      "SV"
    );
  } else {
    return corpBasketManagement.ConfigurationControllerService.addOffering(
      scopeId!,
      tscid!,
      configurationId,
      requestBody as AddOfferingRequestDTO,
      language
    );
  }
}

export function removeOffering(
  scopeId: string | undefined,
  tscid: string | undefined,
  configurationId: string,
  itemId: string,
  language: "SV" | "EN",
  openPages: boolean
): Promise<ConfigurationDTO | ConfigurationOpenDTO> {
  if (openPages) {
    return corpBasketManagement.OpenConfigurationControllerService.deleteOffering1(
      configurationId,
      itemId,
      "SV"
    );
  } else {
    return corpBasketManagement.ConfigurationControllerService.deleteOffering(
      scopeId!,
      tscid!,
      configurationId,
      itemId,
      language
    );
  }
}

<template>
  <telia-form t-id="create-person-form" class="person-form" @submit.prevent novalidate>
    <telia-grid class="person-form__grid">
      <telia-row>
        <telia-col width="12" width-lg="6">
          <telia-text-input
            t-id="create-person-form__first-name"
            class="person-form__first-name-input"
            :label="t('personForm.firstName')"
            name="first-name"
            type="text"
            required
            :required-error-message="t('personForm.formFieldRequired')"
            :value="firstName"
            @input="firstName = $event.target.value"
          />
        </telia-col>
        <telia-col width="12" width-lg="6">
          <telia-text-input
            t-id="create-person-form__last-name"
            class="person-form__last-name-input"
            :label="t('personForm.lastName')"
            name="last-name"
            type="text"
            required
            :required-error-message="t('personForm.formFieldRequired')"
            :value="lastName"
            @input="lastName = $event.target.value"
          />
        </telia-col>
      </telia-row>
      <telia-row>
        <telia-col width="12">
          <telia-text-input
            t-id="create-person-form__email"
            class="person-form__email-input"
            type="email"
            autocomplete="email"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
            :pattern-error-message="t('personForm.emailInvalid')"
            required
            :required-error-message="t('personForm.formFieldRequired')"
            :label="t('personForm.emailAddress')"
            :value="email"
            @input="email = $event.target.value"
          />
        </telia-col>
        <telia-col width="12">
          <telia-text-input
            t-id="create-person-form__phone-number"
            class="person-form__phone-number-input"
            :label="t('personForm.phoneNumber')"
            name="phone-number"
            type="tel"
            pattern="^(?:\+46|0046|0)7[-\d\s]{5,18}\d$"
            maxlength="20"
            :value="phoneNumber"
            required
            :required-error-message="t('personForm.mobileNumberRequired')"
            :pattern-error-message="t('personForm.invalidSwedishMobileNumber')"
            @input="phoneNumber = $event.target.value"
          />
        </telia-col>
      </telia-row>
    </telia-grid>
  </telia-form>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import { isFormValid } from "../../utils/validation";

export default defineComponent({
  name: "person-form",
  mixins: [translateMixin],
  props: {
    initFormData: {
      type: Object,
      default: () => ({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      }),
    },
  },
  data() {
    return {
      firstName: this.initFormData.firstName,
      lastName: this.initFormData.lastName,
      email: this.initFormData.email,
      phoneNumber: this.initFormData.phoneNumber,
    };
  },
  created() {
    translateSetup();
    this.handleChanges();
  },
  computed: {
    isValid() {
      return isFormValid({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
      });
    },
    formEdited() {
      const { firstName, lastName, email, phoneNumber } = this.initFormData;
      if (
        firstName !== this.firstName ||
        lastName !== this.lastName ||
        email !== this.email ||
        phoneNumber !== this.phoneNumber
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    emitFormData() {
      this.$emit("form-data", {
        firstName: this.firstName || "",
        lastName: this.lastName || "",
        email: this.email || "",
        phoneNumber: this.phoneNumber || "",
      });
    },
    emitEdited() {
      this.$emit("edited", this.formEdited);
    },
    emitValid() {
      this.$emit("valid", this.isValid);
    },
    handleChanges() {
      this.emitFormData();
      this.emitValid();
      this.emitEdited();
    },
  },

  watch: {
    firstName: "handleChanges",
    lastName: "handleChanges",
    email: "handleChanges",
    phoneNumber: "handleChanges",
  },
});
</script>

<style lang="scss">
.person-form {
  > form {
    padding-bottom: 0;
  }

  &__grid {
    padding: 0;
  }
}
</style>

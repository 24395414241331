export function isFormValid({ firstName, lastName, email, phoneNumber }) {
  if (!firstName || !lastName || !email || !phoneNumber) {
    return false;
  } else if (email && !emailIsValid(email)) {
    return false;
  } else if (phoneNumber && !phoneNumberIsValid(phoneNumber)) {
    return false;
  }
  return true;
}

export const emailIsValid = (email: string): boolean => {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  return emailRegex.test(email);
};

export const phoneNumberIsValid = (phoneNumber: string): boolean => {
  const phoneRegex = /^(?:\+46|0046|0)7[-\d\s]{5,18}\d$/;
  return phoneRegex.test(phoneNumber);
};

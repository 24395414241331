import { InstallationSettingRequestDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return;

  phoneNumber = phoneNumber.replaceAll(/[-, " "]/g, "");

  if (phoneNumber.startsWith("46")) {
    return phoneNumber?.replace(/^46(\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
  }

  if (phoneNumber.startsWith("+46")) {
    return phoneNumber?.replace(/^\+46(\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
  }

  if (phoneNumber.startsWith("0046")) {
    return phoneNumber?.replace(/^0046(\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
  }

  return phoneNumber?.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "$1-$2 $3 $4");
}

export function formatInstallationSettings(installationSettings: InstallationSettingRequestDTO[]) {
  return installationSettings.map((is) => {
    if (is.createContactInformation && is.createContactInformation.phoneNumber) {
      return {
        ...is,
        createContactInformation: {
          ...is.createContactInformation,
          phoneNumber: phoneNumberToMsisdn(is.createContactInformation.phoneNumber),
        },
      };
    }
    return is;
  });
}

export function phoneNumberToMsisdn(phoneNumber: string) {
  return phoneNumber
    .replace(/^07/, "467")
    .replace(/^\+/, "")
    .replace(/^00/, "")
    .replace(/[\s-]/g, "");
}

<template>
  <div class="display-shipping-address">
    <div class="horizontal">
      <telia-p class="bold" t-id="display-shipping-address__address">
        {{ formatAddress }}
      </telia-p>
      <telia-button
        t-id="display-shipping-address__change"
        variant="text"
        class="display-shipping-address__change-button"
        @click="$emit('change-shipping')"
      >
        {{ t("shippingSetting.change") }}
      </telia-button>
    </div>
    <div v-if="showNotifications" t-id="display-shipping-address__notifications">
      <telia-heading tag="h5" variant="subsection-100">
        {{ t("shippingSetting.notifications") }}
      </telia-heading>
      <telia-p>{{ firstName }} {{ lastName }}</telia-p>
      <telia-p>{{ email }}</telia-p>
      <telia-p>{{ phone }}</telia-p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../../locale";
import { formatPhoneNumber } from "../../../utils/formatting-utils";
import "@telia/b2b-address-search";
import "@telia/b2b-autocomplete-input";
import "@telia/b2x-spinner";

export default defineComponent({
  name: "display-shipping-address",
  mixins: [translateMixin],
  props: {
    address: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      default: () => ({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      }),
    },
  },
  async created() {
    await translateSetup();
  },
  computed: {
    formatAddress() {
      return this.address !== null && this.address.fullAddress;
    },
    email() {
      return this.person.email;
    },
    phone() {
      return formatPhoneNumber(this.person.phoneNumber);
    },
    firstName() {
      return this.person.firstName;
    },
    lastName() {
      return this.person.lastName;
    },
    showNotifications() {
      return this.email || this.phone;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/borders/tokens";
@import "@teliads/components/foundations/typography/tokens";

.display-shipping-address {
  &__change-button {
    padding-left: $telia-spacing-8;
  }
  .bold {
    font-weight: $telia-typography-weight-bold;
  }
}

.bold {
  font-weight: $telia-typography-weight-bold;
}

.horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<template>
  <div>
    <div
      v-for="optionGroup in selectedOffering.optionGroups"
      class="selected-offering__option-group-container"
    >
      <boolean-option-group
        v-if="isBooleanChoiceType(optionGroup)"
        :t-id="`selected-offering__boolean-option-group-${optionGroup.id}`"
        :configuration-update-is-pending="configurationUpdateIsPending"
        :option-group="optionGroup"
        @change="handleChangeEvent"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  OptionGroupDTO,
  SelectedOfferingDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";
import OptionGroup from "./option-group.vue";
import BooleanOptionGroup from "./boolean-option-group.vue";
import { ChangeConfigurationEventPayload } from "../@types/types";

interface Props {
  selectedOffering: SelectedOfferingDTO;
  configurationUpdateIsPending: boolean;
}

defineProps<Props>();
const emit = defineEmits<{
  (e: "change", payload: ChangeConfigurationEventPayload): void;
}>();
function isBooleanChoiceType(optionGroup: OptionGroupDTO): boolean {
  if ((optionGroup?.options ?? []).length === 0) return false;
  return !!optionGroup?.options?.every(
    (option) => option.value === "true" || option.value === "false"
  );
}

function handleChangeEvent(payload: ChangeConfigurationEventPayload): void {
  emit("change", payload);
}
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
.selected-offering {
  &__option-group-container {
    & + & {
      margin-top: $telia-spacing-16;
    }
  }
}
</style>

import { BLOCKS } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const getHeading = (size) => (node, next) =>
  `<telia-heading tag="h${size}" variant="title-100">${next(node.content)}</telia-heading>`;

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: getHeading("1"),
    [BLOCKS.HEADING_2]: getHeading("2"),
    [BLOCKS.HEADING_3]: getHeading("3"),
    [BLOCKS.HEADING_4]: getHeading("4"),
    [BLOCKS.HEADING_5]: getHeading("5"),
    [BLOCKS.HEADING_6]: getHeading("6"),
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<telia-p variant="paragraph-100">${next(node.content)}</telia-p>`,
  },
};

export function toHtml(document) {
  return documentToHtmlString(document, options);
}

<template>
  <div class="configuration-summary" t-id="configuration-summary">
    <telia-heading
      v-if="!configurationLoading"
      t-id="configuration-summary__heading"
      class="configuration-summary__heading"
      tag="h2"
      variant="title-300"
    >
      {{ t("choicesHeader") }}
    </telia-heading>
    <telia-heading
      t-id="configuration-summary__product-name"
      class="configuration-summary__product-name"
      tag="h3"
      variant="title-200"
      v-if="productName"
    >
      {{ productName }}
    </telia-heading>
    <div class="configuration-summary__address" t-id="configuration-summary-address">
      <telia-p variant="paragraph-100">{{ address }}</telia-p>
    </div>
    <div class="configuration-summary__outer-container">
      <b2x-spinner
        class="configuration-summary__spinner"
        t-id="configuration-summary__spinner"
        v-if="configurationLoading || configurationUpdateLoading"
        size="large"
      />
      <div
        t-id="configuration-summary__content-container"
        :class="{ hidden: configurationLoading, transparent: configurationUpdateLoading }"
      >
        <configuration-summary-item :broadband-item="broadbandItem"></configuration-summary-item>
        <configuration-summary-discount
          v-if="broadbandItem && hasDiscount"
          :discounts="broadbandItem.discounts"
        ></configuration-summary-discount>
        <configuration-price-summary
          :onetime-price="onetimePrice"
          :recurring-price="recurringPrice"
          :onetime-price-before-discount="onetimePriceBeforeDiscount"
          :recurring-price-before-discount="recurringPriceBeforeDiscount"
        />
      </div>
    </div>
    <div class="configuration-summary__button-slot">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { translateSetup, translateMixin } from "../../locale";
import ConfigurationPriceSummary from "../configuration-price-summary.vue";
import ConfigurationSummaryItem from "./configuration-summary-item.vue";
import ConfigurationSummaryDiscount from "./configuration-summary-discount.vue";
import type {
  BroadbandItemDTO,
  ConfigurationDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";

interface Props {
  configuration?: ConfigurationDTO;
  configurationLoading: boolean;
  configurationUpdateLoading: boolean;
}
const props = defineProps<Props>();

translateSetup();

const t = translateMixin.methods.t;

const address = computed<string | undefined>(
  () => props.configuration?.configurationItems?.[0]?.installationAddress?.fullAddress
);
const broadbandItem = computed<BroadbandItemDTO | undefined>(
  () => props.configuration?.configuredItems?.[0]
);
const onetimePrice = computed<string>(() => props.configuration?.onetimePrice || "0");
const recurringPrice = computed<string>(() => props.configuration?.recurringPrice || "0");
const onetimePriceBeforeDiscount = computed<string | undefined>(
  () => props.configuration?.onetimePriceBeforeDiscount
);
const recurringPriceBeforeDiscount = computed<string | undefined>(
  () => props.configuration?.recurringPriceBeforeDiscount
);
const productName = computed<string | undefined>(
  () => props.configuration?.configurationItems?.[0]?.name
);
const hasDiscount = computed<boolean>(
  () => !!props.configuration?.configuredItems?.[0].discounts?.length
);
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.configuration-summary {
  background: $telia-white;
  border-radius: 1.6rem;
  padding: $telia-spacing-32;

  &__button-slot button {
    width: 100%;
  }

  &__heading {
    margin-bottom: $telia-spacing-32;
  }

  &__product-name {
    margin-bottom: $telia-spacing-8;
  }

  &__outer-container {
    position: relative;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.transparent {
  opacity: 0.3;
}

.hidden {
  visibility: hidden;
}
</style>

<template>
  <div class="configuration-card-skeleton">
    <configuration-card class="configuration-card-skeleton__option">
      <div class="configuration-card-skeleton__option-heading">
        <telia-skeleton class="skeleton-loader" />
      </div>
      <div class="configuration-card-skeleton__option-paragraph">
        <telia-skeleton class="skeleton-loader" />
        <telia-skeleton class="skeleton-loader" />
        <telia-skeleton class="skeleton-loader" />
      </div>
      <div class="configuration-card-skeleton__option-selected">
        <telia-skeleton class="skeleton-loader" />
        <telia-skeleton class="skeleton-loader" />
      </div>
    </configuration-card>
    <configuration-card class="configuration-card-skeleton__option">
      <div class="configuration-card-skeleton__option-heading">
        <telia-skeleton class="skeleton-loader" />
      </div>
      <div class="configuration-card-skeleton__option-paragraph">
        <telia-skeleton class="skeleton-loader" />
        <telia-skeleton class="skeleton-loader" />
        <telia-skeleton class="skeleton-loader" />
      </div>
      <div class="configuration-card-skeleton__option-selected">
        <telia-skeleton class="skeleton-loader" />
        <telia-skeleton class="skeleton-loader" />
      </div>
    </configuration-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ConfigurationCard from "./configuration-card";

export default defineComponent({
  name: "configuration-card-skeleton",
  components: {
    ConfigurationCard,
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.configuration-card-skeleton {
  &__option {
    margin-bottom: $telia-spacing-24;
    height: 37rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-heading {
      height: 3.7rem;
      width: 45%;
      .skeleton-loader {
        height: 3.7rem;
      }
    }
    &-paragraph {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 7.2rem;
      .skeleton-loader {
        height: 1rem;
        margin-bottom: $telia-spacing-4;
        &:last-child {
          width: 25%;
        }
      }
    }
    &-selected {
      height: 12rem;
      .skeleton-loader {
        height: 5.2rem;
        margin-bottom: $telia-spacing-4;
        &:last-child {
          width: 75%;
        }
      }
    }
  }
}
</style>

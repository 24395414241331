<template>
  <div>
    <telia-heading
      t-id="offering-option__heading"
      class="offering-option__heading"
      tag="h2"
      variant="title-300"
      >{{ offeringOption?.header || offeringOption?.id }}
    </telia-heading>
    <fieldset class="offering-option">
      <legend class="sr-only">{{ offeringOption?.header }}</legend>

      <div
        t-id="offering-option__description"
        class="offering-option__description"
        v-if="offeringOption?.description"
        v-html="richTextToHtml(offeringOption?.description)"
      />
      <telia-p v-if="hasLink" t-id="offering-option__info-link" class="offering-option__info-link">
        <telia-link href="{{offeringOption?.infoLinkUrl}}">{{
          offeringOption?.infoLinkTitle
        }}</telia-link>
      </telia-p>

      <template
        v-if="offeringOption!.cardinality!.min === 0 && offeringOption!.cardinality!.max === 1"
      >
        <telia-heading class="offering-option__option-heading" tag="h3" variant="title-200">{{
          offeringOption?.choiceTitle
        }}</telia-heading>
        <div :key="componentKey" class="offering-option__container offering-option--horizontal">
          <div class="offering-option__option">
            <b2x-radio-card
              t-id="offering-option__option-input-1"
              ref="addOfferingInput"
              class="offering-option__option-input"
              :value="1"
              :label="getLabelForIndex(1)"
              :disabled="configurationUpdateIsPending"
              :name="offeringOption?.id"
              :checked="selectedCardinality === 1"
              @change.prevent.stop="handleAddOffering"
            />
          </div>
          <div class="offering-option__option">
            <b2x-radio-card
              t-id="offering-option__option-input-0"
              ref="removeOfferingInput"
              class="offering-option__option-input"
              :value="0"
              :label="getLabelForIndex(0)"
              :disabled="configurationUpdateIsPending"
              :name="offeringOption?.id"
              :checked="selectedCardinality === 0"
              @change.prevent.stop="handleRemoveOffering"
            />
          </div>
        </div>
      </template>
      <div
        class="offering-option__acceptance"
        t-id="offering-option__acceptance"
        v-if="offeringOption?.cardinality?.choices?.[selectedCardinality]?.acceptance"
      >
        <div
          t-id="offering-option__acceptance_description"
          class="offering-option__acceptance_description"
          v-if="offeringOption?.cardinality.choices[selectedCardinality].description"
          v-html="
            richTextToHtml(offeringOption?.cardinality.choices[selectedCardinality].description)
          "
        />
        <telia-checkbox
          v-if="selectedCardinality === 0"
          required
          t-id="offering-option__acceptance__checkbox-0"
          @change.prevent.stop="handleCheck"
          >{{ offeringOption?.cardinality.choices[0].acceptance }}</telia-checkbox
        >
        <telia-checkbox
          v-else
          required
          t-id="offering-option__acceptance__checkbox-1"
          @change.prevent.stop="handleCheck"
          >{{ offeringOption?.cardinality.choices[1].acceptance }}</telia-checkbox
        >
      </div>
      <div class="offering-option__selected-offerings">
        <selected-offering
          v-for="selectedOffering in offeringOption.selectedOfferings"
          :t-id="`offering-option-selected-offering-${selectedOffering.id}`"
          :selected-offering="selectedOffering"
          :configuration-update-is-pending="configurationUpdateIsPending"
          @change="emit('change', $event)"
        />
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../locale";
import { OfferingOptionDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { computed, ComputedRef, watch, ref, nextTick } from "vue";
import { toHtml } from "../helpers/rich-text-renderer";
import { AcceptedOffering, ChangeConfigurationEventPayload } from "../@types/types";
import SelectedOffering from "./selected-offering.vue";

translateSetup(["mybusinessotpconfigure"]);

const emit = defineEmits<{
  (e: "add-offering", offeringId: string): void;
  (e: "remove-offering", offeringId: string): void;
  (e: "accepted", status: AcceptedOffering): void;
  (e: "change", payload: ChangeConfigurationEventPayload): void;
}>();

const t = translateMixin.methods.t;

interface Props {
  offeringOption: OfferingOptionDTO;
  configurationUpdateIsPending: boolean;
}

const props = defineProps<Props>();

watch(
  () => props.configurationUpdateIsPending,
  (_, wasPending: boolean) => {
    if (wasPending && hasUpdatedValue.value) {
      forceRerender();
      restoreFocusToInputAfterUpdate().then(() => {
        hasUpdatedValue.value = false;
      });
    }
  }
);

const componentKey = ref(0);
const hasUpdatedValue = ref<boolean>(false);
const addOfferingInput = ref<HTMLElement | null>(null);
const removeOfferingInput = ref<HTMLElement | null>(null);

function forceRerender() {
  componentKey.value += 1;
}

function richTextToHtml(richText) {
  const parsedRichText = JSON.parse(richText);
  return toHtml(parsedRichText);
}

async function restoreFocusToInputAfterUpdate() {
  await nextTick();
  let inputToBeFocused;
  if (selectedCardinality.value === 0 && removeOfferingInput.value) {
    inputToBeFocused = removeOfferingInput.value;
  } else if (addOfferingInput.value) {
    inputToBeFocused = addOfferingInput?.value;
  }
  setTimeout(() => {
    inputToBeFocused?.querySelector?.("input")?.focus();
  }, 0);
}

function handleAddOffering(): void {
  hasUpdatedValue.value = true;
  emit("add-offering", props.offeringOption.id!);
}

function handleRemoveOffering(): void {
  hasUpdatedValue.value = true;
  emit("remove-offering", selectedOfferingId.value);
}

function getLabelForIndex(index) {
  let label = "";
  if (props.offeringOption?.cardinality && props.offeringOption?.cardinality?.choices) {
    label = props.offeringOption?.cardinality?.choices?.[index]?.title || "";
  }
  return label;
}

function handleCheck(event, _) {
  emit("accepted", {
    cardinality: selectedCardinality.value,
    checked: event.target.checked,
    offering: props.offeringOption,
  });
}

const hasLink: ComputedRef<boolean> = computed(
  () => !!props.offeringOption?.infoLinkTitle && !!props.offeringOption?.infoLinkUrl
);

const selectedCardinality: ComputedRef<number> = computed(
  () => (props.offeringOption?.selectedOfferings ?? []).length
);
const selectedOfferingId: ComputedRef<string> = computed(
  () => props.offeringOption?.selectedOfferings?.[0]?.id ?? ""
);
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/mixins";

@import "@teliads/components/foundations/borders/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/tokens";

.offering-option {
  border: none;

  &__container {
    display: grid;
    gap: $telia-spacing-16;
  }

  &--horizontal {
    grid-template-columns: repeat(auto-fit, minmax(13.2rem, 1fr));
  }

  &__heading {
    margin-bottom: $telia-spacing-16;
  }

  &__option-heading {
    margin-bottom: $telia-spacing-24;
  }

  &__description {
    margin-bottom: $telia-spacing-32;

    :deep(ul) {
      margin-top: $telia-spacing-32;
      margin-bottom: $telia-spacing-32;
      padding-left: $telia-spacing-24;
    }
  }
  &__info-link {
    margin-bottom: $telia-spacing-32;
  }

  &__acceptance {
    margin-top: $telia-spacing-32;
  }

  &__acceptance_description {
    margin-bottom: $telia-spacing-32;
  }

  &__selected-offerings {
    margin-top: $telia-spacing-32;
  }
}

.sr-only {
  border: 0;
  clip-path: circle(0%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>

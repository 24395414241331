<template>
  <div class="broadband-installation-step">
    <telia-grid>
      <telia-row class="broadband-installation-step__content">
        <telia-col width="12" class="broadband-installation-step__config-column">
          <login-link-box
            t-id="b2b-order-flow-configuration__login-link-box"
            v-if="showLoginLinkBox"
            :target-url-value="targetUrl"
            :selected-organisation="selectedOrganisation"
          />
        </telia-col>
        <telia-col width="12" width-md="7" class="broadband-installation-step__config-column">
          <telia-notification
            heading-tag="h3"
            status="warning"
            t-id="b2b-order-flow-configuration__finish-configuration-error"
            v-if="showFinishConfigurationError"
            class="b2b-order-flow-configuration__finish-configuration-error"
          >
            <span slot="heading">{{ t("finishConfigurationError.heading") }}</span>
            <span slot="content">
              <telia-p>{{ t("finishConfigurationError.message") }}</telia-p>
            </span>
          </telia-notification>
          <template v-else>
            <completion-date-setting
              v-if="!!defaultCompletionDate"
              t-id="installation-setting__completion-date"
              :default-completion-date="defaultCompletionDate"
              @selected-date="$emit('selected-date', $event)"
              @date-valid="handleDateValid"
            />
            <installation-settings
              t-id="broadband-installation-step__installation-settings"
              :settings="settings"
              :party-information-map="partyInformationMap"
              :scope-id="scopeId"
              :tscid="tscid"
              :open-pages="openPages"
              @update-installation-setting="handleUpdateInstallationSetting"
              @is-editing="handleIsEditing"
            />
          </template>
        </telia-col>

        <telia-col width="12" width-md="5">
          <sticky-container>
            <configuration-summary
              :configuration="configuration"
              :configuration-loading="false"
              :configuration-update-loading="false"
            >
              <div v-if="hasNotes">
                <ocn-note
                  v-for="(note, index) in configuration.notes"
                  :key="index"
                  :text="note.text"
                  @accepted-note="handleAcceptOfNote"
                />
              </div>
              <telia-button
                t-id="b2b-order-flow-configuration__add-button"
                variant="primary"
                full-width
                size="lg"
                :disabled="
                  disableButton || (hasNotes && !hasAcceptedAllNotes) || isDirty || !isDateValid
                "
                @click="$emit('add-to-basket')"
              >
                {{ addButtonText }}
              </telia-button>
            </configuration-summary>
          </sticky-container>
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "../../locale";
import ConfigurationSummary from "../configuration-summary/configuration-summary.vue";
import StickyContainer from "../sticky-container.vue";
import LoginLinkBox from "../login-link-box.vue";
import CompletionDateSetting from "../installation-settings/completion-date-setting.vue";
import OcnNote from "./ocn-note.vue";
import InstallationSettings from "../installation-settings/installation-settings.vue";
import { FeatureFlags } from "../../feature-flags/featureFlags";
import { useFlag } from "@unleash/proxy-client-vue";

export default defineComponent({
  name: "installation",
  mixins: [translateMixin],
  components: {
    LoginLinkBox,
    InstallationSettings,
    ConfigurationSummary,
    CompletionDateSetting,
    StickyContainer,
    OcnNote,
  },
  props: {
    configuration: {
      type: Object,
      default: () => ({}),
    },
    disableButton: {
      type: Boolean,
      default: true,
    },
    scopeId: {
      type: String,
      required: false,
    },
    showFinishConfigurationError: {
      type: Boolean,
      required: true,
    },
    tscid: {
      type: String,
      required: false,
    },
    openPages: {
      type: Boolean,
      default: false,
    },
    showLoginLinkBox: { type: Boolean, default: false },
    targetUrl: { type: String },
    selectedOrganisation: { type: String },
    partyInformationMap: { type: Object, default: () => ({}) },
  },
  setup() {
    const redirectToProductListingEnabled = useFlag(FeatureFlags.MULTICHECKOUT);
    return {
      redirectToProductListingEnabled,
    };
  },
  created() {
    translateSetup();
  },
  data() {
    return {
      selectedAddress: null,
      numberOfAcceptedNotes: 0,
      isDirty: false,
      isDateValid: true,
    };
  },
  computed: {
    addButtonText() {
      if (this.openPages) {
        return this.t("goToCheckout");
      } else {
        return this.redirectToProductListingEnabled
          ? this.t("addToBasket")
          : this.t("goToCheckout");
      }
    },
    settings() {
      return this.configuration?.configurationItems[0]?.installationSettings || [];
    },
    hasNotes() {
      return !!this.configuration?.notes;
    },
    hasAcceptedAllNotes() {
      return this.numberOfAcceptedNotes === this.configuration?.notes.length || 0;
    },
    defaultCompletionDate() {
      return this.configuration?.configuredItems[0].estimatedDeliveryDate;
    },
  },
  methods: {
    handleAcceptOfNote(acceptedNote) {
      if (acceptedNote) {
        this.numberOfAcceptedNotes++;
      } else {
        this.numberOfAcceptedNotes--;
      }
    },
    handleIsEditing(event) {
      this.isDirty = event;
    },
    handleUpdateInstallationSetting(event) {
      this.$emit("update-installation-setting", event);
      this.isDirty = false;
    },
    handleDateValid(event) {
      this.isDateValid = event;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/breakpoints/mixins";

.broadband-installation-step {
  margin-top: $telia-spacing-32;

  &__config-column {
    @media (max-width: $telia-breakpoint-medium) {
      margin-bottom: $telia-spacing-32;
    }

    display: grid;
    gap: $telia-spacing-24;
  }

  &__content {
    display: flex;
    justify-content: center;

    telia-notification {
      margin-bottom: $telia-spacing-16;
    }
  }
}
</style>

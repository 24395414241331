<template>
  <div>
    <ul v-if="optionGroups">
      <li v-for="optionGroup in optionGroups as OptionGroupDTO[]" :key="optionGroup.id">
        <configuration-card>
          <option-group-container
            :optionGroup="optionGroup"
            :configurationUpdateIsPending="configurationUpdateIsPending"
            @change="emit('change', $event)"
          />
        </configuration-card>
      </li>
    </ul>
    <ul v-if="offeringOptions.length > 0">
      <li
        v-for="(offeringOption, index) in offeringOptions as OfferingOptionDTO[]"
        :key="offeringOption.id"
      >
        <configuration-card>
          <offering-option-container
            :t-id="`offering-option-container-${index}`"
            :offeringOption="offeringOption"
            :configurationUpdateIsPending="configurationUpdateIsPending"
            @change="emit('change', $event)"
            @add-offering="
              emit('add-offering', { offeringId: $event, bundleId: configurationItem.id! })
            "
            @remove-offering="emit('remove-offering', $event)"
            @accepted="emit('accepted-offering', $event)"
          />
        </configuration-card>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type { ComputedRef, Ref } from "vue";
import ConfigurationCard from "./configuration-card.vue";
import OptionGroupContainer from "./option-group-container.vue";
import { FeatureFlags } from "../feature-flags/featureFlags";
import { useFlag } from "@unleash/proxy-client-vue";
import type {
  ConfigurationItemDTO,
  OfferingOptionDTO,
  OptionGroupDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";
import OfferingOptionContainer from "./offering-option-container.vue";
import {
  AcceptedOffering,
  AddOfferingPayload,
  ChangeConfigurationEventPayload,
} from "../@types/types";

interface Props {
  configurationItem: ConfigurationItemDTO;
  configurationUpdateIsPending: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "change", payload: ChangeConfigurationEventPayload): void;
  (e: "add-offering", payload: AddOfferingPayload): void;
  (e: "remove-offering", offeringId: string): void;
  (e: "accepted-offering", status: AcceptedOffering): void;
}>();

const useGettingStartedEnabled = useFlag(FeatureFlags.GETTING_STARTED);
const optionGroups: ComputedRef<OptionGroupDTO[] | undefined> = computed(
  () => props.configurationItem?.optionGroups
);

const offeringOptions: ComputedRef<OfferingOptionDTO[]> = computed(() => {
  const offeringOptionsFiltered = useGettingStartedEnabled.value
    ? props.configurationItem.offeringOptions!
    : props.configurationItem.offeringOptions!.filter(
        (oo) => oo.id !== "PO_SE_GettingStartedBroadband"
      );

  // This filtering is due to PublicStaticIP offering being released before it was ready..
  return offeringOptionsFiltered.filter((oo) => oo.id !== "PO_SE_PublicStaticIP_OCN");
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li + li {
  margin-top: $telia-spacing-24;
}

ul + ul {
  margin-top: $telia-spacing-24;
}
</style>

import { B2bCheckoutDetails, B2bSelectedOrganisation } from "../@types/types";

const B2B_CHECKOUT_DETAILS = "b2b_checkout_details";
const B2B_SELECTED_ORGANISATION = "b2b_selected_organisation";

export function getCheckoutDetails(): B2bCheckoutDetails {
  return JSON.parse(window.sessionStorage.getItem(B2B_CHECKOUT_DETAILS) ?? "{}");
}

export function setCheckoutDetails(b2bCheckoutDetails: B2bCheckoutDetails) {
  window.sessionStorage.setItem(B2B_CHECKOUT_DETAILS, JSON.stringify(b2bCheckoutDetails));
}

export function getSelectedOrganisation(): B2bSelectedOrganisation {
  return JSON.parse(window.sessionStorage.getItem(B2B_SELECTED_ORGANISATION) ?? "{}");
}

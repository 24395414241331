import { identity as removeEmptyValues, trim } from "./fp";

export function getFullName(name) {
  return [name?.firstName, name?.lastName].filter(removeEmptyValues).map(trim).join(" ");
}

export function toDisplayPerson(individual, personSettingId) {
  if (!individual) return;

  const { contactInformation } = extractContactInfoByRole(individual, personSettingId);
  return {
    firstName: individual?.name?.firstName || "",
    lastName: individual?.name?.lastName || "",
    email: getEmailFromContactInformation(contactInformation),
    phoneNumber: getPhoneNumberFromContactInformation(contactInformation),
  };
}

export function isPersonEqual(originPerson, comparePerson) {
  for (const [key, value] of Object.entries(comparePerson)) {
    if (originPerson[key] !== value) return false;
  }
  return true;
}

function extractContactInfoByRole(individual, personSettingId) {
  return individual?.roles?.find((info) => info.roleType === personSettingId) || {};
}

function getEmailFromContactInformation(contactInformation) {
  return contactInformation?.find((d) => d.type === "EMAIL")?.value || "";
}

function getPhoneNumberFromContactInformation(contactInformation) {
  return contactInformation?.find((d) => d.type === "PHONE_NUMBER")?.value || "";
}

<template>
  <telia-grid t-id="display-person__grid" class="display-person__grid">
    <telia-row>
      <telia-col width="10">
        <telia-p t-id="display-person__full-name">
          <span class="display-person__bold">{{ fullName }}</span>
        </telia-p>
        <telia-p v-if="contactInfo" t-id="display-person__contact-info">
          {{ contactInfo }}
        </telia-p>
      </telia-col>
      <telia-col width="2">
        <telia-button
          t-id="display-person__change-button"
          class="display-person__change-button"
          size="sm"
          variant="text"
          @click="$emit('change-person')"
          >{{ t("createPerson.change") }}
        </telia-button>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../../locale";
import { formatPhoneNumber } from "../../../utils/formatting-utils";
import { getFullName } from "../../../utils/user-utils";

export default defineComponent({
  name: "display-person",
  mixins: [translateMixin],
  props: { person: { type: Object, require: true } },
  created() {
    translateSetup();
  },
  computed: {
    fullName() {
      return getFullName(this.person);
    },
    contactInfo() {
      const prettyPhoneNumber = formatPhoneNumber(this.person.phoneNumber);
      return `${this.person.email || ""}, ${prettyPhoneNumber || ""}`;
    },
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/typography/variables";

.display-person {
  &__grid {
    padding: 0;
  }

  &__bold {
    font-weight: $telia-typography-weight-medium;
  }
  &__change-button {
    margin-top: -0.8rem;
  }
}
</style>

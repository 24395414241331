<template>
  <fieldset
    t-id="boolean-option-group"
    :key="componentKey"
    v-if="optionGroup"
    class="boolean-option-group"
  >
    <legend class="sr-only">{{ optionGroup.header }}</legend>
    <div
      t-id="boolean-option-group_description"
      v-if="descriptionRichText"
      class="boolean-option-group__description-container"
      v-html="descriptionRichText"
    ></div>
    <telia-checkbox
      t-id="boolean-option-group_checkbox"
      ref="booleanOptionGroupCheckbox"
      :key="optionGroup.id"
      :name="optionGroup.id"
      :checked="isChecked"
      :disabled="configurationUpdateIsPending"
      @change.prevent="handleChange"
      >{{ optionGroup.header }}</telia-checkbox
    >
  </fieldset>
</template>

<script setup lang="ts">
import { computed, ComputedRef, ref, watch, nextTick } from "vue";
import { OptionGroupDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { toHtml } from "../helpers/rich-text-renderer";
import { ChangeConfigurationEventPayload } from "../@types/types";
interface Props {
  optionGroup: OptionGroupDTO;
  configurationUpdateIsPending: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "change", payload: ChangeConfigurationEventPayload): void;
}>();

watch(
  () => props.configurationUpdateIsPending,
  (_, wasPending: boolean) => {
    if (wasPending && hasUpdatedValue.value) {
      forceRerender();
      restoreFocusToInputAfterUpdate().then(() => {
        hasUpdatedValue.value = false;
      });
    }
  }
);

const componentKey = ref(0);
const booleanOptionGroupCheckbox = ref<HTMLElement | null>(null);
const hasUpdatedValue = ref<boolean>(false);

const isChecked: ComputedRef<boolean> = computed<boolean>(
  () => props.optionGroup.selected === "true"
);
const descriptionRichText: ComputedRef<string> = computed<string>(() => {
  if (!props.optionGroup.description) return "";
  try {
    const parsedRichText = JSON.parse(props.optionGroup.description);
    return toHtml(parsedRichText);
  } catch {
    return "";
  }
});

function handleChange(event: Event) {
  hasUpdatedValue.value = true;
  const target = event.target as HTMLInputElement;
  const payload: ChangeConfigurationEventPayload = {
    id: props.optionGroup.id!,
    value: target.checked.toString(),
    configurationPatchIndex: props.optionGroup.configurationPatchIndex!,
  };
  emit("change", payload);
}

function forceRerender() {
  componentKey.value += 1;
}

async function restoreFocusToInputAfterUpdate() {
  await nextTick();
  setTimeout(() => {
    booleanOptionGroupCheckbox?.value?.querySelector?.("input")?.focus();
  }, 0);
}
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.boolean-option-group {
  border: none;

  &__description-container {
    margin-bottom: $telia-spacing-32;
  }
}
.sr-only {
  border: 0;
  clip-path: circle(0%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>

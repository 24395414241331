import {
  BROADBAND_BRAND,
  BROADBAND_CATEGORY,
  BROADBAND_CUPON_CODE,
  BROADBAND_LISTNAME,
  BROADBAND_NEW,
  BROADBAND_SUBCATEGORY,
  BROADBAND_VARIANT,
  BROADBAND_CHECKOUT_START,
} from "./ga-constants";
import {
  trackLoginEvent,
  trackAddToCart,
  trackViewItem,
  trackBeginCheckout,
  trackFunnelFail,
  AgreementType,
  Salesflow,
} from "@telia/b2b-ecommerce-tracking";
import { TrackFunnelFailArgs } from "@telia/b2b-ecommerce-tracking/dist/interfaces";

export function trackClickLogin() {
  trackLoginEvent();
}

export function trackBroadbandProductAddToCart(
  configuration,
  hasFrameAgrement,
  isOpenPages,
  isLoggedIn: boolean
) {
  trackAddToCart({
    product: buildBroadbandProduct(configuration)[0],
    listName: BROADBAND_LISTNAME,
    agreementType: hasFrameAgrement ? AgreementType.NFA : AgreementType.SA,
    salesflow: isOpenPages ? Salesflow.OP : Salesflow.MB,
    totalPrice: configuration.recurringPrice,
    isOpenPages,
    isLoggedIn,
  });
}

export function trackBroadbandProductDetail(
  configuration,
  hasFrameAgrement,
  isOpenPages,
  isLoggedIn
) {
  trackViewItem({
    product: buildBroadbandProduct(configuration)[0],
    listName: BROADBAND_LISTNAME,
    agreementType: hasFrameAgrement ? AgreementType.NFA : AgreementType.SA,
    salesflow: isOpenPages ? Salesflow.OP : Salesflow.MB,
    totalPrice: configuration.recurringPrice,
    isOpenPages,
    isLoggedIn,
  });
}

export function trackBroadbandProductCheckout(
  configuration,
  hasFrameAgrement,
  isOpenPages,
  isLoggedIn
) {
  trackBeginCheckout({
    products: buildBroadbandProduct(configuration),
    listName: BROADBAND_LISTNAME,
    agreementType: hasFrameAgrement ? AgreementType.NFA : AgreementType.SA,
    stepNumber: "1",
    stepLabel: BROADBAND_CHECKOUT_START,
    salesflow: isOpenPages ? Salesflow.OP : Salesflow.MB,
    totalPrice: configuration.recurringPrice,
    isOpenPages,
    isLoggedIn,
  });
}

function buildBroadbandProduct(configuration) {
  return configuration.configurationItems.map((product) => {
    return {
      id: product.productId,
      name: product.name,
      price: configuration.recurringPrice,
      category: BROADBAND_CATEGORY,
      subCategory: BROADBAND_SUBCATEGORY,
      brand: BROADBAND_BRAND,
      quantity: "1",
      variant: BROADBAND_VARIANT,
      recurringPrice: configuration.recurringPrice,
      onetimePrice: configuration.onetimePrice,
      couponCode: BROADBAND_CUPON_CODE,
      newOrExtend: BROADBAND_NEW,
    };
  });
}

export async function successOrFunnelFail<T>(
  restCall: () => Promise<T>,
  funnelFailOptions: TrackFunnelFailArgs
): Promise<T> {
  try {
    return await restCall();
  } catch (e) {
    trackFunnelFail(funnelFailOptions);
    throw e;
  }
}

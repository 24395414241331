<template>
  <div class="shipping-setting">
    <div class="shipping-setting__heading">
      <telia-heading tag="h3" variant="title-300">{{ heading }}</telia-heading>
    </div>
    <div class="shipping-setting__info">
      <telia-p variant="paragraph-100">{{ t("shippingSetting.info") }}</telia-p>
    </div>
    <div v-if="isEditing">
      <edit-shipping-address
        t-id="shipping-setting__edit-shipping"
        :scope-id="scopeId"
        :address="address"
        :default-person="personWithContactInfo"
        :open-pages="openPages"
        @submit-shipping="handleSubmit"
        @cancel-shipping="handleCancel"
      />
    </div>
    <div v-else class="shipping-setting__display-border">
      <display-shipping-address
        t-id="shipping-setting__display-shipping"
        :address="address"
        :person="personWithContactInfo"
        @change-shipping="handleChange"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../../locale";
import DisplayShippingAddress from "./display-shipping-address.vue";
import EditShippingAddress from "./edit-shipping-address.vue";
import { toDisplayPerson } from "../../../utils/user-utils";

export default defineComponent({
  name: "shipping-setting",
  mixins: [translateMixin],
  components: { DisplayShippingAddress, EditShippingAddress },
  props: {
    addressSetting: {
      type: Object,
      required: true,
    },
    personSetting: {
      type: Object,
    },
    scopeId: {
      type: String,
      required: false,
    },
    tscid: {
      type: String,
      required: false,
    },
    openPages: { type: Boolean, default: false },
    partyInformationMap: { type: Object, default: () => ({}) },
  },
  async created() {
    await translateSetup();
  },
  data() {
    return {
      hasConfirmedAddress: false,
      newPerson: null,
      isEditing: false,
      selectedAddress: null,
    };
  },
  computed: {
    address() {
      return this.selectedAddress || this.defaultAddress;
    },
    defaultAddress() {
      return this.addressSetting.address || null;
    },
    partyInformation() {
      return this.partyInformationMap[this.personSetting?.relatedParty];
    },
    personWithContactInfo() {
      return (
        this.newPerson ||
        (this.partyInformation && toDisplayPerson(this.partyInformation, "technicalContact")) ||
        {}
      );
    },
    heading() {
      return this.t("shippingSetting.heading");
    },
  },
  methods: {
    setIsEditing(editing) {
      this.isEditing = editing;
      this.$emit("is-editing", editing);
    },
    handleCancel() {
      this.setIsEditing(false);
    },
    handleChange() {
      this.setIsEditing(true);
    },
    handleNewPerson(person) {
      this.newPerson = person;
      this.$emit("update-installation-setting", {
        value: this.newPerson || this.personSetting.partyId,
        settingId: this.personSetting.id,
      });
    },
    handleSubmit({ address, person }) {
      this.setIsEditing(false);
      this.onConfirmAddress(address);
      this.handleNewPerson(person);
    },
    onConfirmAddress(address) {
      if (address !== null) {
        this.hasConfirmedAddress = true;
        this.selectedAddress = address;

        this.$emit("update-installation-setting", {
          value: this.selectedAddress.pointId,
          settingId: this.addressSetting.id,
        });
        this.setIsEditing(false);
      }
    },
    onResetAddress(showSearchInput) {
      this.hasConfirmedAddress = false;
      this.setIsEditing(showSearchInput);
    },
  },
  watch: {
    tscid() {
      this.onResetAddress(false);
    },
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/borders/tokens";

.shipping-setting {
  background: $telia-white;
  border-radius: 1.6rem;
  padding: $telia-spacing-32;

  &__heading {
    padding-bottom: $telia-spacing-16;
  }

  &__info {
    margin-bottom: $telia-spacing-20;
  }

  &__display-border {
    padding: $telia-spacing-12 $telia-spacing-24;
    border: 1px solid $telia-gray-500;
    border-radius: $telia-border-radius-8;
  }
}
</style>

<template>
  <div>
    <telia-heading
      t-id="option-group-container__heading"
      class="option-group-container__heading"
      tag="h2"
      variant="title-300"
      >{{ optionGroup.header || optionGroup.id }}
    </telia-heading>
    <div
      t-id="option-group-container__description"
      class="option-group-container__description"
      v-if="optionGroup.description"
      v-html="richTextToHtml(optionGroup.description)"
    />
    <option-group
      :configuration-update-is-pending="configurationUpdateIsPending"
      :option-group="optionGroup"
      @change="$emit('change', $event)"
    />
    <div
      v-if="showChoice(optionGroup)"
      class="option-group-container__choice"
      t-id="option-group-container__choice"
    >
      <telia-heading
        v-if="!containsRichTextHeading(this.getSelectedOption(optionGroup).description)"
        tag="h3"
        variant="title-100"
        >{{ this.getSelectedOption(optionGroup).label }}
      </telia-heading>
      <div
        class="option-group-container__choice-description"
        v-html="richTextToHtml(this.getSelectedOption(optionGroup).description)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import OptionGroup from "./option-group.vue";
import { toHtml } from "../helpers/rich-text-renderer";
import { richTextContainsText, richTextContainsHeading } from "../utils/richtextUtil";

export default defineComponent({
  name: "option-group-container",
  components: { OptionGroup },
  props: {
    optionGroup: {
      type: Object,
      required: true,
    },
    configurationUpdateIsPending: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    richTextToHtml(richText) {
      const parsedRichText = JSON.parse(richText);
      return toHtml(parsedRichText);
    },
    showChoice(optionGroup) {
      return (
        optionGroup.isHorizontal &&
        optionGroup.selected &&
        this.containsRichTextData(this.getSelectedOption(optionGroup).description)
      );
    },
    containsRichTextData(description) {
      return richTextContainsText(JSON.parse(description));
    },
    containsRichTextHeading(description) {
      return richTextContainsHeading(JSON.parse(description));
    },
    getSelectedOption(optionGroup) {
      return optionGroup.options.find((option) => option.value === optionGroup.selected);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/borders/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/tokens";
.option-group-container {
  &__heading {
    margin-bottom: $telia-spacing-16;
  }

  &__description {
    margin-bottom: $telia-spacing-20;
  }

  &__choice {
    margin-top: $telia-spacing-16;
    padding: $telia-spacing-16;
    border: $telia-border-width-2 solid $telia-purple-500;
    border-radius: $telia-border-radius-8;
  }
}
</style>

<style lang="scss">
@import "@teliads/components/foundations/spacing/tokens";

.option-group-container__choice-description ul {
  margin-left: $telia-spacing-16;
}
</style>

export function resolve(obj, path) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
}

function isEqual(a, b) {
  return a === b;
}

function getPropFromObj(prop, obj) {
  return obj[prop];
}

export function curry(fn, arity = fn.length) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (function curried(prevArgs: any[]) {
    return function nextCurried(...nextArgs) {
      const args = [...prevArgs, ...nextArgs];
      if (args.length >= arity) {
        return fn(...args);
      }
      return curried(args);
    };
  })([]);
}

export const identity = (x) => x;

export const trim = (x) => x.trim();
export const pick = curry(getPropFromObj);

export const isEqualTo = curry(isEqual);

export const compose = (...fnx) => fnx.reduceRight((f, g) => (...args) => g(f(...args)));

export const filter = (predicate) => (reduceFn) => (acc, curr, idx) =>
  predicate(curr) ? reduceFn(acc, curr, idx) : acc;

export const map = (transformFn) => (reduceFn) => (acc, curr, idx) =>
  reduceFn(acc, transformFn(curr), idx);

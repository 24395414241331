<template>
  <div class="sticky-container">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.sticky-container {
  position: sticky;
  top: $telia-spacing-24;
}
</style>

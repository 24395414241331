<template>
  <div class="note__container" t-id="note">
    <telia-p t-id="note__content" class="note__content">{{ text }}</telia-p>
    <telia-checkbox @click="handleAcceptClick" t-id="note__accept-checkbox">
      <span>{{ t("acceptOcnNote") }}</span>
    </telia-checkbox>
  </div>
</template>

<script setup lang="ts">
import { translateSetup, translateMixin } from "../../locale";

translateSetup();

interface Props {
  text: string;
}
const t = translateMixin.methods.t;

defineProps<Props>();
const emit = defineEmits<{
  (e: "accepted-note", checked: boolean): void;
}>();

function handleAcceptClick(e: CustomEvent): void {
  const target = e.target as HTMLInputElement;
  if (target && target.checked !== undefined) {
    emit("accepted-note", target.checked);
  }
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/borders/variables";

.note {
  &__container {
    border-radius: $telia-border-radius-16;
    background-color: $telia-orange-200;
    padding: $telia-spacing-16;
    margin-bottom: $telia-spacing-32;
  }

  &__content {
    margin-bottom: $telia-spacing-24;
  }
}
</style>

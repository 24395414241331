<template>
  <div class="create-person">
    <person-form
      t-id="create-person-form"
      @form-data="handleFormData"
      @valid="handleFormValid"
      @edited="handleFormEdited"
    />
    <div class="create-person-form__action-container-buttons">
      <telia-button
        t-id="create-person-form__revert-button"
        class="create-person-form__button"
        variant="secondary"
        type="button"
        :disabled="disableRevert"
        @click="$emit('revert')"
        >{{ t("createPerson.revert") }}
      </telia-button>
      <telia-button
        t-id="create-person-form__submit-button"
        class="create-person-form__button"
        variant="primary"
        type="submit"
        :disabled="createButtonDisabled"
        @click="handleCreatePerson"
      >
        {{ t("createPerson.update") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../../locale";
import PersonForm from "../person-form.vue";

export default defineComponent({
  name: "create-person",
  mixins: [translateMixin],
  components: { PersonForm },
  props: { disableRevert: { type: Boolean, default: true } },
  data() {
    return {
      personFormData: null,
      personFormValid: false,
      personFormEdited: false,
    };
  },
  created() {
    translateSetup();
  },
  computed: {
    createButtonDisabled() {
      return !this.personFormEdited || !this.personFormValid;
    },
  },
  methods: {
    handleFormData(formData) {
      this.personFormData = formData;
    },
    handleFormEdited(formEdited) {
      this.personFormEdited = formEdited;
    },
    handleFormValid(formValid) {
      this.personFormValid = formValid;
    },
    handleCreatePerson() {
      this.$emit("submit-create-person", this.personFormData);
    },
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.create-person-form {
  > form {
    padding-bottom: 0;
  }

  &__grid {
    padding: 0;
  }

  &__action-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: $telia-spacing-32;
    padding-top: $telia-spacing-24;
    border-top: $telia-border-width-1 solid $telia-gray-200;
    &-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__button + &__button {
    margin-left: $telia-spacing-12;
  }
}
</style>

<template>
  <div class="completion-date-setting">
    <div class="completion-date-setting__heading">
      <telia-heading tag="h3" variant="title-300">{{ t("completionDate.heading") }}</telia-heading>
    </div>
    <div class="completion-date-setting__info">
      <telia-p variant="paragraph-100">{{ t("completionDate.description") }}</telia-p>
    </div>
    <div class="completion-date-setting__modes">
      <b2x-radio-card
        t-id="completion-date-setting__asap"
        class="completion-date-setting__modes-choice"
        name="completion-date-mode"
        :value="completionDateMode.ASAP"
        :label="t('completionDate.asap.heading')"
        :checked="selectedCompletionDateMode === completionDateMode.ASAP"
        @change="changeCompletionDateMode"
      >
        <telia-p>{{ t("completionDate.asap.content") }}</telia-p>
      </b2x-radio-card>
      <b2x-radio-card
        t-id="completion-date-setting__future"
        class="completion-date-setting__modes-choice"
        name="completion-date-mode"
        :value="completionDateMode.FUTURE"
        :label="t('completionDate.future.heading')"
        :checked="selectedCompletionDateMode === completionDateMode.FUTURE"
        @change="changeCompletionDateMode"
      >
        <telia-p>{{ t("completionDate.future.content") }}</telia-p>
      </b2x-radio-card>
    </div>
    <telia-date-picker
      class="completion-date-setting__date"
      t-id="requested-completion-date"
      ref="requestedCompletionDate"
      :key="componentRef"
      :label="t('completionDate.datePicker.label')"
      :value="defaultCompletionDate"
      :min="defaultCompletionDate"
      :max="maxCompletionDate"
      :disabled="datePickerIsDisabled"
      :invalid-error-message="t('completionDate.datePicker.invalidMessage')"
      @vocaChange="onCompletionDateChanged($event.detail.value)"
    />
    <div class="completion-date-setting__disclaimer">
      <telia-p variant="paragraph-100">{{ t("completionDate.disclaimer") }}</telia-p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "../../locale";
import "@telia/b2x-radio-card";

export default defineComponent({
  name: "completion-date-setting",
  mixins: [translateMixin],
  props: { defaultCompletionDate: { type: String, required: true } },
  data() {
    return {
      completionDateMode: Object.freeze({
        ASAP: "asap",
        FUTURE: "future",
      }),
      selectedCompletionDateMode: null,
      componentRef: 0,
    };
  },
  created() {
    this.selectedCompletionDateMode = this.completionDateMode.ASAP;
    translateSetup();
  },
  computed: {
    maxCompletionDate() {
      const maxDate = new Date(this.defaultCompletionDate);
      maxDate.setMonth(maxDate.getMonth() + 6);
      return maxDate.toISOString().split("T")[0];
    },
    datePickerIsDisabled() {
      return this.selectedCompletionDateMode === this.completionDateMode.ASAP;
    },
  },
  methods: {
    changeCompletionDateMode(event) {
      this.selectedCompletionDateMode = event.target.value;
      this.forceRerenderOfDatePicker();
      if (this.selectedCompletionDateMode === this.completionDateMode.ASAP) {
        this.$refs.requestedCompletionDate.value = this.defaultCompletionDate;
        this.$emit("selected-date", this.defaultCompletionDate);
        this.$emit("date-valid", true);
      }
    },
    onCompletionDateChanged(date) {
      if (date && this.isDateInRange(new Date(date))) {
        this.$emit("selected-date", date);
        this.$emit("date-valid", true);
      } else {
        this.$emit("date-valid", false);
      }
    },
    isDateInRange(date) {
      const minDate = new Date(this.defaultCompletionDate);
      const maxDate = new Date(this.maxCompletionDate);
      return date >= minDate && date <= maxDate;
    },
    forceRerenderOfDatePicker() {
      this.componentRef++;
    },
  },
  watch: {
    defaultCompletionDate: {
      handler() {
        if (this.selectedCompletionDateMode === this.completionDateMode.ASAP) {
          this.$refs.requestedCompletionDate.value = this.defaultCompletionDate;
          this.$emit("selected-date", this.defaultCompletionDate);
          this.$emit("date-valid", true);
        }
      },
    },
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/breakpoints/variables.scss";

.completion-date-setting {
  background: $telia-white;
  border-radius: 1.6rem;
  padding: $telia-spacing-32;

  &__heading {
    padding-bottom: $telia-spacing-16;
  }

  &__info {
    margin-bottom: $telia-spacing-32;
  }

  &__disclaimer {
    margin-top: $telia-spacing-32;
  }

  &__modes {
    display: grid;
    gap: $telia-spacing-16;
    margin-bottom: $telia-spacing-32;
    @media (min-width: $telia-breakpoint-large) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__modes-choice {
    .radio-card {
      height: 100%;
    }
  }
}
</style>

export function richTextContainsHeading(richText) {
  return (
    richText?.nodeType?.includes("heading") ||
    richText?.content?.some((rtc) => richTextContainsHeading(rtc))
  );
}

export function richTextContainsText(richText) {
  return richText?.value || richText?.content?.some((rtc) => richTextContainsText(rtc));
}

<template>
  <div class="edit-shipping-address">
    <div class="edit-shipping-address__address-search">
      <b2b-address-search
        t-id="edit-shipping-address__address-search"
        type="search"
        :scope-id="scopeId"
        :debounce-timeout-ms="300"
        :open-pages="openPages"
        :default-address="JSON.stringify(address)"
        @addressSelected="handleAddressSelected"
      />
    </div>
    <div class="edit-shipping-address__person-form">
      <person-form
        t-id="edit-shipping-address__person-form"
        :init-form-data="defaultPerson"
        @form-data="handleFormData"
        @valid="handleFormValid"
        @edited="handleFormEdited"
      />
    </div>
    <div class="edit-shipping-address__action-container-buttons">
      <telia-button
        t-id="edit-shipping-address__revert-button"
        class="edit-shipping-address__button"
        variant="secondary"
        type="button"
        @click="cancel"
        >{{ t("shippingSetting.revert") }}
      </telia-button>
      <telia-button
        t-id="edit-shipping-address__submit-button"
        class="edit-shipping-address__button"
        variant="primary"
        type="submit"
        :disabled="disableSubmit"
        @click="submit"
      >
        {{ t("shippingSetting.update") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../../locale";
import PersonForm from "../person-form.vue";

import "@telia/b2b-address-search";
import "@telia/b2b-autocomplete-input";
import "@telia/b2x-spinner";

export default defineComponent({
  name: "edit-shipping-address",
  mixins: [translateMixin],
  components: { PersonForm },
  props: {
    address: {
      type: Object,
      required: true,
    },
    defaultPerson: {
      type: Object,
      required: false,
    },
    scopeId: {
      type: String,
      required: false,
    },
    openPages: { type: Boolean, default: false },
  },
  data() {
    return {
      newAddress: null,
      personFormData: null,
      personFormValid: false,
      personFormEdited: false,
    };
  },
  async created() {
    await translateSetup();
  },
  computed: {
    disableSubmit() {
      return (!this.personFormEdited && !this.newAddress) || !this.personFormValid;
    },
  },
  methods: {
    handleAddressSelected(event) {
      if (event.detail !== null) {
        const addressSelected = event.detail;
        if (addressSelected.pointId !== this.address.pointId) {
          this.newAddress = addressSelected;
        }
      }
    },
    handleFormData(formData) {
      this.personFormData = formData;
    },
    handleFormEdited(formEdited) {
      this.personFormEdited = formEdited;
    },
    handleFormValid(formValid) {
      this.personFormValid = formValid;
    },
    submit() {
      this.$emit("submit-shipping", { address: this.newAddress, person: this.personFormData });
    },
    cancel() {
      this.$emit("cancel-shipping");
    },
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.edit-shipping-address {
  &__person-form {
    margin-top: $telia-spacing-24;
  }

  &__action-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: $telia-spacing-32;
    padding-top: $telia-spacing-24;
    border-top: $telia-border-width-1 solid $telia-gray-200;
    &-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__button + &__button {
    margin-left: $telia-spacing-12;
  }
}
</style>

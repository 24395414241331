import { match } from "path-to-regexp";
import { navigateToUrl } from "single-spa";

interface Match {
  params: Params;
}

interface Params {
  tscid: string;
  scopeId: string;
  configurationId: string;
}

export function getParamsFromUrl(openPages, url = window.location.pathname) {
  if (openPages) {
    const { params } = match("/foretag/bredband/anpassa/:configurationId")(url) as Match;
    return { configurationId: params.configurationId };
  } else {
    const { params } = match(
      "/foretag/mybusiness/:scopeId/bestall/:tscid/anpassa/:configurationId"
    )(url) as Match;
    return params;
  }
}

export function getNavigationFunction(isOpenPages: boolean) {
  return isOpenPages ? navigateByLocationHref : navigateToUrl;
}

function navigateByLocationHref(url: string) {
  window.location.href = url;
}

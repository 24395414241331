// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/cjs.js??clonedRuleSet-5.use[1]!../../../common/temp/node_modules/.pnpm/@purpurds+purpur@5.13.0_react-dom@18.3.1_react@18.3.1/node_modules/@purpurds/purpur/dist/purpur.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--purpur-rescale: 1.6}", "",{"version":3,"sources":["webpack://./src/main.scss"],"names":[],"mappings":"AAEA,MACE,qBAAA","sourcesContent":["@import \"@purpurds/purpur/dist/purpur.css\";\n\n:root {\n  --purpur-rescale: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

<template>
  <div t-id="b2b-order-flow-configuration" class="b2b-order-flow-configuration">
    <b2b-layout
      t-id="b2b-order-flow-configuration__layout"
      :heading="pageHeader"
      :pageTitle="t('pageTitle')"
      :organisation-selector-disabled="true"
      :back-link-label="t('goBack')"
      :back-link-href="pickProductUrl"
      show-organisation-selector
      :selected-organisation="tscid"
    >
      <span slot="header">
        <telia-grid>
          <telia-row>
            <telia-col width="12">
              <telia-p t-id="b2b-order-flow-configuration__subheading" variant="preamble-200"
                >{{ t("mybusinessotpconfigure.configurePageSubheading") }}
              </telia-p>
            </telia-col>
          </telia-row>
        </telia-grid>
      </span>
      <div class="b2b-order-flow-configuration__container">
        <div class="order-flow-stepper">
          <PurpurStepper
            data-testid="stepper"
            t-id="stepper"
            variant="primary"
            :scrollButtonLabels="{
              leftScrollButtonText: t('stepper.leftScrollButtonText'),
              rightScrollButtonText: t('stepper.rightScrollButtonText'),
            }"
            :completedStepLabel="t('stepper.completedLabel')"
            :currentStepIndex="currentStep"
            @stepChange="handleStepChange"
          >
            <PurpurStepperContent :label="t('stepper.chooseProduct')" :finished="true">
              <span> </span>
            </PurpurStepperContent>
            <PurpurStepperContent :label="t('stepper.configure')" :finished="currentStep > 1">
              <div>
                <broadband-configuration-step
                  class="b2b-order-flow-configuration__content"
                  :configuration="configuration"
                  :configuration-update-is-pending="configurationUpdateIsPending"
                  :get-configuration-pending="getConfigurationPending"
                  :get-configuration-error="getConfigurationError"
                  :has-update-error="hasUpdateError"
                  :selected-organisation="selectedOrganisation?.name"
                  :target-url="targetUrl"
                  :show-login-link-box="showLoginLinkBox"
                  :next-step-is-disabled="installationStepDisabled"
                  @selected-option="handleConfigurationItemChange"
                  @update-error="hasUpdateError = false"
                  @next-step="nextStep"
                  @add-offering="handleAddOffering"
                  @remove-offering="handleRemoveOffering"
                  @accepted-offering="handleAcceptedOffering"
                />
              </div>
            </PurpurStepperContent>
            <PurpurStepperContent
              :label="t('stepper.installation')"
              :disabled="installationStepDisabled"
            >
              <div>
                <broadband-installation-step
                  v-if="!getConfigurationPending"
                  class="b2b-order-flow-configuration__content"
                  :show-finish-configuration-error="finishConfigurationError"
                  :disable-button="disableAddButton"
                  :configuration="configuration"
                  :party-information-map="partyInformationMap"
                  :scopeId="scopeId"
                  :tscid="tscid"
                  :open-pages="openPages"
                  :selected-organisation="selectedOrganisation?.name"
                  :target-url="targetUrl"
                  :show-login-link-box="showLoginLinkBox"
                  @add-to-basket="handleAddToBasket"
                  @update-installation-setting="updateSetting($event)"
                  @selected-date="setSelectedDate($event)"
                />
              </div>
            </PurpurStepperContent>
          </PurpurStepper>
        </div>
      </div>
    </b2b-layout>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import {
  finishConfiguration,
  getConfigurationById,
  updateConfiguration,
  addOffering,
  removeOffering,
} from "../../services/basket-management-service";
import { corpLogin } from "@telia/b2b-rest-client";
import { getIndividualByPartyId } from "../../services/party-information-service";
import BroadbandConfigurationStep from "./broadband-configuration-step";
import BroadbandInstallationStep from "./broadband-installation-step.vue";
import {
  getPartiesFromConfiguration,
  installationSettingsFromConfiguration,
} from "./configurationUtils";
import { currentLanguage } from "@telia/b2b-i18n";
import {
  successOrFunnelFail,
  trackBroadbandProductAddToCart,
  trackBroadbandProductCheckout,
  trackBroadbandProductDetail,
} from "../../helpers/ga-helper";
import { domEmit } from "../../utils/customEmitHelper";
import { FeatureFlags } from "../../feature-flags/featureFlags";
import { useFlag } from "@unleash/proxy-client-vue";
import {
  getSelectedOrganisation,
  getCheckoutDetails,
  setCheckoutDetails,
} from "../../utils/session-storage-utils";
import { navigateToUrl } from "single-spa";
import { getNavigationFunction } from "../../utils/urlUtils";
import { formatInstallationSettings } from "../../utils/formatting-utils";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import { applyPureReactInVue } from "veaury";
import { Stepper } from "@purpurds/purpur";

function getPickProductUrl(openPages, configuration, scopeId, tscid) {
  const queryParam = configuration?.configurationItems?.[0]
    ? `?point-id=${configuration.configuredItems[0].installationAddress.pointId}`
    : "";
  const path = openPages
    ? `/foretag/bredband/bestall`
    : `/foretag/mybusiness/${scopeId}/bestall/${tscid}/bredband`;
  const url = `${path}${queryParam}`;
  return url;
}

export default defineComponent({
  name: "BroadbandConfiguration",
  mixins: [translateMixin],
  components: {
    PurpurStepper: applyPureReactInVue(Stepper),
    PurpurStepperContent: applyPureReactInVue(Stepper.Content),
    BroadbandConfigurationStep,
    BroadbandInstallationStep,
  },
  props: {
    tscid: { type: String, required: false },
    scopeId: { type: String, required: false },
    configurationId: { type: String, required: true },
    openPages: { type: Boolean, default: false },
  },
  data() {
    return {
      acceptedOfferings: [],
      configuration: null,
      currentStep: 1,
      isLoggedIn: false,
      pendingFinishRequest: false,
      finishConfigurationError: false,
      configurationUpdateIsPending: false,
      getConfigurationPending: true,
      getConfigurationError: false,
      installationSettings: [],
      hasUpdateError: false,
      startDate: null,
      selectedOrganisation: null,
      partyInformationMap: {},
    };
  },
  setup() {
    const redirectToProductListingEnabled = useFlag(FeatureFlags.MULTICHECKOUT);
    return {
      redirectToProductListingEnabled,
    };
  },
  async created() {
    await translateSetup(["mybusinessotpconfigure"]);
    await this.getLoggedInStatus();
    await this.getConfiguration();
    if (this.configuration) {
      this.openPages && this.storePointId();
      this.setSelectedDate(this.configuration.configuredItems[0].estimatedDeliveryDate);
      this.selectedOrganisation = getSelectedOrganisation();
    }
  },
  computed: {
    disableAddButton() {
      return this.pendingFinishRequest || !this.installationSettingsFilled;
    },
    installationSettingsFilled() {
      if (this.openPages) {
        return (
          this.installationSettings
            .filter((setting) => setting.type !== "PERSON")
            .filter((setting) => !(setting.value || setting.createContactInformation)).length === 0
        );
      } else {
        return (
          this.installationSettings.filter(
            (setting) => !(setting.value || setting.createContactInformation)
          ).length === 0
        );
      }
    },
    productName() {
      return this.configuration?.configurationItems?.at(0)?.name;
    },
    pageHeader() {
      return this.productName
        ? this.t("mybusinessotpconfigure.configurePageHeading", { productName: this.productName })
        : undefined;
    },
    currentLocation() {
      return window.location.href;
    },
    pickProductUrl() {
      return getPickProductUrl(this.openPages, this.configuration, this.scopeId, this.tscid);
    },
    targetUrl() {
      return `${window.location.origin}/foretag/bredband/organisationsval?return-url=${this.currentLocation}`;
    },
    showLoginLinkBox() {
      return this.openPages && (!this.isLoggedIn || !!this.selectedOrganisation?.name);
    },
    installationStepDisabled() {
      return (
        this.getConfigurationError ||
        this.configurationUpdateIsPending ||
        this.getConfigurationPending ||
        this.hasUnacceptedOffering
      );
    },
    hasUnacceptedOffering() {
      return this.acceptedOfferings.length < this.offeringsRequiringAcceptance().length;
    },
    pageSpecificClass() {
      return this.openPages ? "open-pages" : "mybusiness";
    },
  },
  methods: {
    getFunnelFailOptions(errorMessage, agreementType = null) {
      return {
        isLoggedIn: this.isLoggedIn,
        totalPrice: this.configuration?.recurringPrice ?? "0",
        isOpenPages: this.openPages,
        errorMessage,
        agreementType:
          agreementType || (this.hasFrameAgreement() ? AgreementType.NFA : AgreementType.SA),
      };
    },
    async getLoggedInStatus() {
      try {
        const { isLoggedIn } = await corpLogin.IsLoggedInControllerService.isLoggedIn();
        this.isLoggedIn = isLoggedIn;
      } catch (e) {
        // Ignore error
      }
    },
    setInstallationSettings(configuration) {
      this.installationSettings = installationSettingsFromConfiguration(
        configuration,
        this.installationSettings
      );
    },
    setSelectedDate(date) {
      this.startDate = date;
    },
    updateSetting(setting) {
      const { settingId, value } = setting;
      this.installationSettings = this.installationSettings.map((is) => {
        if (is.settingId !== settingId) {
          return is;
        }
        return is.type === "PERSON"
          ? { ...is, value: undefined, createContactInformation: value }
          : { ...is, value };
      });
    },
    handleAddToBasket() {
      if (this.openPages) {
        domEmit(this.$el, "add-to-basket", {
          configurationId: this.configuration.id,
          settings: formatInstallationSettings(this.installationSettings),
          installationPointId: this.configuration.configuredItems[0].installationAddress.pointId,
          startDate: this.startDate,
        });
        trackBroadbandProductAddToCart(this.configuration, false, this.openPages, this.isLoggedIn);
        trackBroadbandProductCheckout(this.configuration, false, this.openPages, this.isLoggedIn);
      } else {
        this.finishConfiguration();
      }
    },
    async finishConfiguration() {
      trackBroadbandProductAddToCart(
        this.configuration,
        this.hasFrameAgreement(),
        this.openPages,
        this.isLoggedIn
      );
      this.finishConfigurationError = false;
      this.pendingFinishRequest = true;
      try {
        const { id } = await successOrFunnelFail(
          () =>
            finishConfiguration(this.scopeId, this.tscid, this.configurationId, {
              settings: formatInstallationSettings(this.installationSettings),
              startDate: this.startDate,
            }),
          this.getFunnelFailOptions("Could not finish configuration")
        );
        if (this.redirectToProductListingEnabled) {
          this.goToBrowse();
        } else {
          trackBroadbandProductCheckout(
            this.configuration,
            this.hasFrameAgreement(),
            this.openPages,
            this.isLoggedIn
          );
          this.goToCheckout(id);
        }
      } catch {
        this.finishConfigurationError = true;

        this.pendingFinishRequest = false;
      }
    },
    async getConfiguration() {
      this.getConfigurationError = false;
      this.getConfigurationPending = true;
      try {
        this.configuration = await successOrFunnelFail(
          () =>
            getConfigurationById(
              this.scopeId,
              this.tscid,
              this.configurationId,
              currentLanguage().toUpperCase(),
              this.openPages
            ),
          this.getFunnelFailOptions("Could not get configuration", "n/a")
        );
        this.setInstallationSettings(this.configuration);
        await this.populatePartyInformationMap(this.configuration);
        trackBroadbandProductDetail(
          this.configuration,
          this.hasFrameAgreement(),
          this.openPages,
          this.isLoggedIn
        );
      } catch {
        this.getConfigurationError = true;
      } finally {
        this.getConfigurationPending = false;
      }
    },
    async handleConfigurationItemChange(changeObject) {
      this.hasUpdateError = false;
      this.configurationUpdateIsPending = true;
      try {
        this.configuration = await successOrFunnelFail(
          () =>
            updateConfiguration(
              this.scopeId,
              this.tscid,
              this.configurationId,
              currentLanguage().toUpperCase(),
              changeObject,
              this.openPages
            ),
          this.getFunnelFailOptions("Could not update configuration")
        );
        this.setInstallationSettings(this.configuration);
        await this.populatePartyInformationMap(this.configuration);
      } catch {
        this.hasUpdateError = true;
      } finally {
        this.configurationUpdateIsPending = false;
      }
    },
    goToCheckout(id) {
      navigateToUrl(`/foretag/mybusiness/${this.scopeId}/bestall/${this.tscid}/kassa/${id}`);
    },
    goToBrowse() {
      navigateToUrl(
        `/foretag/mybusiness/${this.scopeId}/bestall/${this.tscid}/bredband?show-basket=true`
      );
    },
    nextStep() {
      this.currentStep += 1;
      scrollStepperIntoView();
    },
    async populatePartyInformationMap(configuration) {
      const installationSettingsWithRelatedParty = getPartiesFromConfiguration(configuration);

      for (let setting of installationSettingsWithRelatedParty) {
        if (this.partyInformationMap[setting.relatedParty]) {
          continue;
        }
        try {
          const partyInformation = await getIndividualByPartyId(this.scopeId, setting.relatedParty);
          if (!partyInformation) {
            continue;
          }
          this.partyInformationMap[setting.relatedParty] = partyInformation;
        } catch (e) {
          // Ignore error
        }
      }
    },
    handleStepChange(nextStep) {
      // Choose product step should route back to product listing page
      if (nextStep === 0) {
        const navigationFunction = getNavigationFunction(this.openPages);
        navigationFunction(this.pickProductUrl);
      }
      this.currentStep = nextStep;
      this.finishConfigurationError = false;
    },
    hasFrameAgreement() {
      return !!this.configuration?.frameAgreementId;
    },
    async handleAddOffering({ offeringId, bundleId }) {
      this.hasUpdateError = false;
      this.configurationUpdateIsPending = true;
      this.acceptedOfferings = [];
      try {
        this.configuration = await successOrFunnelFail(
          () =>
            addOffering(
              this.scopeId,
              this.tscid,
              this.configurationId,
              { offeringId, bundleId },
              currentLanguage().toUpperCase(),
              this.openPages
            ),
          this.getFunnelFailOptions("Could not add offering to configuration")
        );
        this.setInstallationSettings(this.configuration);
        await this.populatePartyInformationMap(this.configuration);
      } catch {
        this.hasUpdateError = true;
      } finally {
        this.configurationUpdateIsPending = false;
      }
    },
    async handleRemoveOffering(offeringId) {
      this.hasUpdateError = false;
      this.configurationUpdateIsPending = true;
      this.acceptedOfferings = [];
      try {
        this.configuration = await successOrFunnelFail(
          () =>
            removeOffering(
              this.scopeId,
              this.tscid,
              this.configurationId,
              offeringId,
              currentLanguage().toUpperCase(),
              this.openPages
            ),
          this.getFunnelFailOptions("Could not remove offering from configuration")
        );
        this.setInstallationSettings(this.configuration);
        await this.populatePartyInformationMap(this.configuration);
      } catch {
        this.hasUpdateError = true;
      } finally {
        this.configurationUpdateIsPending = false;
      }
    },
    storePointId() {
      const pointId = this.configuration.configuredItems[0].installationAddress.pointId;
      if (getCheckoutDetails()?.installationPointId !== pointId) {
        setCheckoutDetails({
          installationPointId: pointId,
        });
      }
    },
    offeringsRequiringAcceptance() {
      return this.configuration?.configurationItems
        .flatMap((o) => o.offeringOptions)
        .filter((offering) => {
          return !!this.needsAccept(offering);
        });
    },
    needsAccept(offering) {
      let choiceNeedsAccept = (index) =>
        offering.selectedOfferings.length === index &&
        offering?.cardinality?.choices?.[index]?.acceptance;

      return choiceNeedsAccept(0) || choiceNeedsAccept(1);
    },
    handleAcceptedOffering(status) {
      if (status.offering.cardinality.choices[status.cardinality].acceptance !== null) {
        if (status.checked) {
          this.acceptedOfferings.push(status.offering.offeringId);
        } else {
          this.acceptedOfferings = this.acceptedOfferings.filter(
            (offering) => offering !== status.offering.offeringId
          );
        }
      } else {
        this.acceptedOfferings.push(status.offering.offeringId);
      }
    },
  },
});

function scrollStepperIntoView() {
  const element = document.getElementById("page-header");
  if (!element) {
    return;
  }
  const pos = element.offsetTop;

  if (!isInViewport(element)) {
    setTimeout(() => {
      window.scrollTo({
        top: pos,
        behavior: "smooth",
      });
    }, 100);
  }
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
</script>

<style lang="scss">
@import "@purpurds/purpur/styles";
.order-flow-stepper * {
  box-sizing: border-box;
}
</style>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.b2b-order-flow-configuration {
  &__container {
    margin-top: calc($telia-spacing-32 * -1); // to remove gap between banner and stepper
  }
  &__content {
    padding: $telia-spacing-12 0;
  }
}
</style>

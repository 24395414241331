<template>
  <div class="configuration-card">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "configuration-card",
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.configuration-card {
  background: $telia-white;
  border-radius: 1.6rem;
  padding: $telia-spacing-32;
}
</style>

<template>
  <div ref="root" class="login-link-box">
    <div v-if="!selectedOrganisation" class="login-link-box__card">
      <form
        t-id="login-link-box__form"
        @submit="handleSubmit"
        name="login-form"
        method="post"
        action="/.api/corp-login/login"
      >
        <input
          t-id="login-link-box__input"
          type="hidden"
          name="targetUrl"
          :value="targetUrlValue"
        />

        <telia-p class="login-link-box__text">
          {{ t("login.message") }}

          <telia-button
            t-id="login-link-box__button"
            class="login-link-box__button"
            type="submit"
            variant="text"
            >{{ t("login.linkMessage") }}</telia-button
          >

          {{ t("login.endMessage") }}
        </telia-p>
      </form>
    </div>
    <div v-else class="login-link-box__selected-organisation-box" t-id="selected-organisation-box">
      <telia-p class="login-link-box__selected-organisation-text">
        {{ t("selectedOrganisation.selected") }}
        <strong> {{ selectedOrganisation }} </strong>
      </telia-p>
      <telia-button
        class="login-link-box__selected-organisation-button"
        variant="text"
        @click="handleChange"
      >
        {{ t("selectedOrganisation.change") }}
      </telia-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { domEmit } from "../utils/customEmitHelper";
import { trackClickLogin } from "../helpers/ga-helper";
import { translateMixin, translateSetup } from "../locale";

translateSetup();
const t = translateMixin.methods.t;

const root = ref(null);

interface Props {
  targetUrlValue: string;
  selectedOrganisation?: string;
}

const props = defineProps<Props>();

function handleChange() {
  domEmit(root.value, "go-to-organisation-selector", props.targetUrlValue);
}

function handleSubmit() {
  trackClickLogin();
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/breakpoints/tokens";

.login-link-box {
  padding: 0 0 $telia-spacing-48 0;

  &__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $telia-white;
    border-radius: 1.6rem;
    padding: $telia-spacing-16 $telia-spacing-32;
  }

  &__button {
    display: inline-flex;
    margin: calc(-1 * $telia-spacing-16) 0;
  }

  &__selected-organisation {
    &-box {
      display: flex;
      flex-wrap: wrap;
      background: $telia-white;
      border-radius: 1.6rem;
      padding: $telia-spacing-4 $telia-spacing-32;
    }
    &-text {
      margin: $telia-spacing-12 $telia-spacing-12 $telia-spacing-12 $telia-spacing-0;
    }
  }
}
</style>

<script setup lang="ts">
import { Ref, withDefaults } from "vue";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm";
import B2bOrderFlowConfiguration from "./b2b-order-flow-configuration.vue";
const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId(
  "b2b-order-flow-configuration"
);

interface Props {
  openPages?: boolean;
}
withDefaults(defineProps<Props>(), { openPages: false });
</script>

<template>
  <FlagProvider v-if="client !== null" :unleash-client="client" :start-client="false">
    <B2bOrderFlowConfiguration :open-pages="openPages" />
  </FlagProvider>
</template>

<style scoped lang="scss"></style>

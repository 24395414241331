<template>
  <div class="installation-settings">
    <div v-for="setting of filteredSettings" :key="setting.id">
      <shipping-setting
        v-if="setting.id === 'shippingAddress'"
        t-id="installation-setting__shipping-address"
        :address-setting="setting"
        :party-information-map="partyInformationMap"
        :person-setting="shippingContact"
        :scope-id="scopeId"
        :tscid="tscid"
        :open-pages="openPages"
        @update-installation-setting="$emit('update-installation-setting', $event)"
        @is-editing="$emit('is-editing', $event)"
      />
      <order-contact-setting
        v-else-if="setting.type === 'PERSON'"
        t-id="installation-setting__setting-contact"
        :person="setting"
        :party-information-map="partyInformationMap"
        @update-installation-setting="
          $emit('update-installation-setting', { value: $event, settingId: setting.id })
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "../../locale";
import OrderContactSetting from "./order-contact-setting/order-contact-setting.vue";
import ShippingSetting from "./shipping-setting/shipping-setting.vue";

export default defineComponent({
  name: "installation-settings",
  mixins: [translateMixin],
  components: { OrderContactSetting, ShippingSetting },
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
    scopeId: {
      type: String,
      required: false,
    },
    tscid: {
      type: String,
      required: false,
    },
    openPages: { type: Boolean, default: false },
    partyInformationMap: { type: Object, default: () => ({}) },
  },
  created() {
    translateSetup();
  },
  data() {
    return {
      activeSettings: ["shippingAddress", "technicalContact", "localContact"],
      hasConfirmedAddress: false,
      isLoadingAddress: false,
      selectedAddress: null,
    };
  },
  computed: {
    filteredSettings() {
      return this.settings.filter((setting) => this.activeSettings.includes(setting.id));
    },
    shippingContact() {
      return this.settings.find((setting) => setting.id === "shippingContact");
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.installation-settings {
  display: grid;
  gap: $telia-spacing-24;
}
</style>

<template>
  <fieldset t-id="option-group" v-if="options" class="option-group">
    <legend class="sr-only">{{ optionGroup.header }}</legend>
    <div :key="componentKey" class="option-group__container" :class="getOptionGroupClass()">
      <div
        v-for="(option, idx) in options"
        :t-id="`option-group__option-${option.value}`"
        class="option-group__option"
        :key="option.value"
      >
        <b2x-radio-card
          ref="optionRefs"
          class="option-group__option-input"
          :value="option.value"
          :label="option.label"
          :disabled="configurationUpdateIsPending"
          :name="optionGroup.id"
          :checked="isChecked(option)"
          @change.prevent.stop="handleInput(option.value!, idx)"
        />
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import "@telia/b2x-radio-card";
import type { OptionDTO, OptionGroupDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { computed, ref, nextTick, watch } from "vue";

interface Props {
  optionGroup: OptionGroupDTO;
  configurationUpdateIsPending: boolean;
}

interface ChangeEventPayload {
  id: string;
  value: string;
  configurationPatchIndex: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "change", payload: ChangeEventPayload): void;
}>();
const indexOfItemBeingUpdated = ref<number | null>(null);
const componentKey = ref<number>(0);
const optionRefs = ref<HTMLElement[]>([]);

const options = computed<OptionDTO[] | undefined>(() => props.optionGroup.options);

function getOptionGroupClass() {
  return `option-group--${props.optionGroup.isHorizontal ? "horizontal" : "vertical"}`;
}

function handleInput(value: string, idx: number) {
  indexOfItemBeingUpdated.value = idx;
  emit("change", {
    id: props.optionGroup.id!,
    value: value,
    configurationPatchIndex: props.optionGroup.configurationPatchIndex!,
  });
}

function isChecked(option: OptionDTO) {
  return option.value === props.optionGroup.selected;
}

async function restoreFocusToInputAfterUpdate() {
  if (indexOfItemBeingUpdated.value === null) {
    return;
  }
  const ref = optionRefs.value[indexOfItemBeingUpdated.value];
  setTimeout(async () => {
    await nextTick();
    ref.querySelector?.("input")?.focus?.();
  }, 0);
}

function forceRender() {
  componentKey.value += 1;
}

watch(
  () => props.configurationUpdateIsPending,
  async (_: boolean, wasPending: boolean) => {
    if (wasPending && indexOfItemBeingUpdated.value !== null) {
      forceRender();
      await nextTick();
      restoreFocusToInputAfterUpdate().then(() => {
        indexOfItemBeingUpdated.value = null;
      });
    }
  }
);
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/mixins";

@import "@teliads/components/foundations/borders/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/breakpoints/variables.scss";

.option-group {
  border: none;

  &__container {
    display: grid;
    gap: $telia-spacing-16;
  }

  &--horizontal {
    @media (min-width: $telia-breakpoint-large) {
      grid-template-columns: repeat(auto-fit, minmax(13.2rem, 1fr));
    }
  }

  &__option {
    $option: &;
    position: relative;
    flex: 1 1 auto;

    &-input {
      &:checked {
        & + #{$option}-label {
          @include telia-border("purple-500", "sm", "all");
        }

        &:disabled + #{$option}-label {
          @include telia-border("purple-300", "sm", "all");
        }
      }

      &:disabled + #{$option}-label {
        color: $telia-gray-400;
        @include telia-border("gray-300", "sm", "all");
        cursor: not-allowed;
      }
    }

    &-label {
      @include telia-border("gray-500", "sm", "all");
      border-radius: $telia-border-radius-8;
      display: flex;
      justify-content: center;
      padding: $telia-spacing-12;
      cursor: pointer;
    }
  }
}

.sr-only {
  border: 0;
  clip-path: circle(0%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>
